import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetCustomerAttachmentsQuery } from "src/api";
import { TextColumnFilter } from "src/components";
import { createTableModule, TableNames } from "src/table";
import type { AttachmentDto } from "src/types";
import { bytesFormatter } from "src/utils";

const columnHelper = createColumnHelper<AttachmentDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("fileName", {
        id: "fileName",
        header: t("customers.attachments.table.name") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("fileSize", {
        id: "fileSize",
        header: t("customers.attachments.table.size") as string,
        enableColumnFilter: false,
        cell: (cellContext) => bytesFormatter(cellContext.getValue()),
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("contentType", {
        id: "contentType",
        header: t("customers.attachments.table.contentType") as string,
        enableColumnFilter: false,
        meta: {
            minWidth: 100,
        },
    }),
];

export const tableModule = createTableModule<AttachmentDto>(TableNames.customerAttachments, useGetCustomerAttachmentsQuery, createColumns);
