import { useDebouncedValue, useLocalStorage } from "@mantine/hooks";
import type { ColumnDef } from "@tanstack/react-table";
import type { FC } from "react";
import type { TFunction } from "react-i18next";

import type { AnyRecord, TableRecordType } from "src/types";
import { isEmpty, useAppParamSelector } from "src/utils";
import { LOADING_DELAY } from "./constants";
import { TableContainer } from "./containers";
import type { TableNames } from "./enums";
import { getSavedFilters } from "./selectors";
import type { QueryHookType, TableContainerProps, TableId, TableQueryParams } from "./types";
import { useData, useInitialFiltersValuesFromUrl } from "./utils";

type ContainerProps<RecordType extends TableRecordType> = { additionalQueryParams?: AnyRecord } & Pick<
    TableContainerProps<RecordType>,
    "actions" | "overrideRowProps" | "isSelectable" | "emptyRow" | "isExpandable" | "actionsForSelection" | "canSelectAll" | "maxWidth"
> &
    Partial<Pick<TableContainerProps<RecordType>, "defaultTableConfig">>;

const createContainer =
    <RecordType extends TableRecordType>(
        tableName: TableNames,
        tableId: TableId,
        useGetData: QueryHookType<RecordType>,
        createColumns: (t: TFunction) => ColumnDef<RecordType, RecordType>[],
    ): FC<ContainerProps<RecordType>> =>
    ({
        actions,
        overrideRowProps,
        defaultTableConfig,
        isSelectable,
        isExpandable,
        canSelectAll = true,
        emptyRow,
        actionsForSelection,
        additionalQueryParams,
        maxWidth,
    }) => {
        const [persistedPageSize, setPersistedPageSize] = useLocalStorage<number | null>({
            key: `${tableName}-pageSize`,
            defaultValue: null,
        });
        const savedFilters = useAppParamSelector(getSavedFilters, tableName);
        const initialFilters =
            !isEmpty(savedFilters) && Object.keys(savedFilters).length !== 0 ? savedFilters : defaultTableConfig?.initialFilters || {};
        const initialFilterValuesFromUrl = useInitialFiltersValuesFromUrl(tableName, initialFilters);
        const defaultTableQueryParams: TableQueryParams = {
            pageSize: persistedPageSize || defaultTableConfig?.pageSize || 10,
            pageIndex: defaultTableConfig?.pageIndex || 0,
            filters: initialFilterValuesFromUrl,
        };
        const queryOptions = {
            pollingInterval: defaultTableConfig?.pooling,
        };
        const { data, isLoading, isFetching, isSuccess, fetchWithNewParams } = useData(
            useGetData,
            defaultTableQueryParams,
            additionalQueryParams,
            queryOptions,
        );
        const [debouncedLoading] = useDebouncedValue<boolean>(isLoading || isFetching, LOADING_DELAY);

        return (
            <TableContainer<RecordType>
                tableId={tableId}
                tableName={tableName}
                isLoading={debouncedLoading}
                createColumns={createColumns}
                actions={actions}
                overrideRowProps={overrideRowProps}
                data={data ? data.content : []}
                initialFilterValuesFromUrl={initialFilterValuesFromUrl}
                defaultTableConfig={{
                    pageSize: defaultTableQueryParams.pageSize,
                    pageIndex: defaultTableQueryParams.pageIndex,
                    initialFilters: defaultTableConfig?.initialFilters || {},
                }}
                pageCount={isSuccess ? Math.ceil(data.totalElements / data.pageSize) : 0}
                fetchWithNewParams={fetchWithNewParams}
                isSelectable={isSelectable}
                isExpandable={isExpandable}
                emptyRow={emptyRow}
                totalElements={isSuccess ? data.totalElements : 0}
                actionsForSelection={actionsForSelection}
                canSelectAll={canSelectAll}
                setPersistedPageSize={setPersistedPageSize}
                maxWidth={maxWidth}
            />
        );
    };

export default createContainer;
