import { parse } from "querystring";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import type { AnyRecord, TableRecordType } from "src/types";
import { FILTERS_SEARCH_PARAM_BASE } from "./constants";
import type { TableNames } from "./enums";
import type { QueryHookType, TableQueryParams, UseDataReturnType } from "./types";

export const createFiltersSearchParam = (tableName: TableNames) => `${FILTERS_SEARCH_PARAM_BASE}_${tableName}`;

export const useData = <RecordType extends TableRecordType>(
    useGetData: QueryHookType<RecordType>,
    defaultParams: TableQueryParams,
    additionalQueryParams: AnyRecord = {},
    queryOptions: AnyRecord = {},
): UseDataReturnType<RecordType> => {
    const [queryParams, setQueryParams] = useState<TableQueryParams>(defaultParams);

    const response = useGetData(
        {
            ...queryParams,
            filters: { ...queryParams.filters, ...additionalQueryParams },
        },
        queryOptions,
    );

    return { ...response, fetchWithNewParams: setQueryParams };
};

export const useInitialFiltersValuesFromUrl = (tableName: TableNames, initialValues: AnyRecord): AnyRecord => {
    const [searchParams] = useSearchParams();
    const filtersInParamsAsString = searchParams.get(createFiltersSearchParam(tableName));
    return useMemo(() => {
        if (filtersInParamsAsString) {
            const filtersInParams = parse(filtersInParamsAsString);
            return { ...initialValues, ...filtersInParams };
        }
        return initialValues;
    }, [filtersInParamsAsString, initialValues]);
};
