import type {
    CreateProductPackageDto,
    CreateProductPriceDto,
    ProductBrandDto,
    ProductBrandWithMarginsFilterDto,
    ProductBrandWithSupplyLimitsDto,
    ProductPackageDto,
    ProductPackageWithPricesDto,
    ProductPriceDto,
    SupplyLimitWithPackageAndMarginDto,
    UpdateMarginsForPayerDto,
    UpdateProductPriceDto,
} from "../generated";
import type { ExpandTableType } from "../helpers";

export type ProductPackageWithPrices = ProductPackageWithPricesDto & Partial<Pick<ProductPriceDto, "price" | "validFrom" | "validTo">>;
export type ProductPrice = ProductPriceDto & { name: string; current?: boolean };
export type ExpandTableProductPackageWithPricesType = ExpandTableType<ProductPackageWithPrices, ProductPrice>;
export type ExpandTableProductType = ExpandTableType<ProductBrandDto, ProductPackageDto>;
export type ExpandTableProductWithSupplyLimitsType = ExpandTableType<ProductBrandWithSupplyLimitsDto, SupplyLimitWithPackageAndMarginDto>;
export type CreateProductPackage = Omit<CreateProductPackageDto, "price"> & { price: { validFrom: Date; price: number } };
export type CreateProductPrice = Omit<CreateProductPriceDto, "validFrom" | "validTo"> & { validFrom: Date; validTo?: Date };
export type UpdateProductPrice = Omit<UpdateProductPriceDto, "validFrom" | "validTo"> & { validFrom: Date; validTo?: Date };

export type CreateAndUpdateProductPrice = CreateProductPrice | UpdateProductPrice;

type ProductBrandWithMarginsFilterDtoFixed = Omit<ProductBrandWithMarginsFilterDto, "marginValidityTimestamp"> & {
    marginValidityTimestamp?: ProductBrandWithMarginsFilterDto["marginValidityTimestamp"];
};
export type UpdateMarginsForPayerDtoFixed = Omit<UpdateMarginsForPayerDto, "filterDto"> & {
    filterDto: ProductBrandWithMarginsFilterDtoFixed;
};

export const isProductBrandWithSupplyLimitsDto = (
    obj: ProductBrandWithSupplyLimitsDto | SupplyLimitWithPackageAndMarginDto,
): obj is ProductBrandWithSupplyLimitsDto => "supplyLimits" in obj && "name" in obj && !isSupplyLimitWithPackageAndMarginDto(obj);
export const isSupplyLimitWithPackageAndMarginDto = (
    obj: ProductBrandWithSupplyLimitsDto | SupplyLimitWithPackageAndMarginDto,
): obj is SupplyLimitWithPackageAndMarginDto => "productPackageName" in obj && "margin" in obj && "tierNumber" in obj;

export const isProductPackageWithPrices = (obj: ProductPackageWithPrices | ProductPrice): obj is ProductPackageWithPrices =>
    "name" in obj && "prices" in obj && !isProductPrice(obj);
export const isProductPrice = (obj: ProductPackageWithPrices | ProductPrice): obj is ProductPrice =>
    "productPackageId" in obj && "validFrom" in obj && "validTo" in obj;

export const isProductBrandDto = (obj: ProductBrandDto | ProductPackageDto): obj is ProductBrandDto =>
    "name" in obj && "archived" in obj && !isProductPackageDto(obj);
export const isProductPackageDto = (obj: ProductBrandDto | ProductPackageDto): obj is ProductPackageDto =>
    "productBrandId" in obj && "agentCode" in obj && "sapCode" in obj;
