import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetPayersDistributionMarginQuery } from "src/api";
import { HighlightCell, SelectColumnFilter, TextColumnFilter, Tooltip } from "src/components";
import { createTableModule, ParentRow, TableNames } from "src/table";
import { Color } from "src/theme";
import {
    type ExpandTableDistributionMarginsType,
    type ProductBrandWithMarginsDto,
    type ProductPackageWithMarginDto,
    isProductBrandWithMarginsDto,
    isProductPackageWithMarginDto,
    MarginSourceEnum,
} from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue, percentageFormatter } from "src/utils";

const columnHelper = createColumnHelper<ProductBrandWithMarginsDto | ProductPackageWithMarginDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("productPackageName", {
        id: "productPackageName",
        header: t("payerDetail.distributionMargins.table.name") as string,
        cell: ({ row }) =>
            isProductBrandWithMarginsDto(row.original) ? <ParentRow>{row.original.name}</ParentRow> : row.original.productPackageName,
        meta: { minWidth: 150, filterComponent: TextColumnFilter, expandedRowColspan: 2 },
    }),
    columnHelper.accessor("margin", {
        id: "margin",
        header: t("payerDetail.distributionMargins.table.margin") as string,
        filterFn: "includesStringSensitive",
        cell: ({ row }) =>
            isProductPackageWithMarginDto(row.original) && (
                <Tooltip
                    withinPortal={false}
                    backgroundColor={Color.accent50}
                    color={Color.supportGraphite500}
                    withArrow
                    label={getTranslatedEnumValue("marginSource", row.original.marginSource)}
                >
                    <HighlightCell highlight={row.original.marginSource !== MarginSourceEnum.ProductPackage}>
                        {percentageFormatter(row.original.margin)}
                    </HighlightCell>
                </Tooltip>
            ),
        meta: {
            minWidth: 120,
            align: "end",
            options: enumToLocalizedOptions("marginSource", MarginSourceEnum, [
                MarginSourceEnum.InheritedFromPayer,
                MarginSourceEnum.SpecialPriceInheritedFromPayer,
            ]),
            filterComponent: SelectColumnFilter,
        },
    }),
];

export const tableModule = createTableModule<ExpandTableDistributionMarginsType>(
    TableNames.payerDistributionMargin,
    useGetPayersDistributionMarginQuery,
    createColumns,
);
