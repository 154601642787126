import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetEmergencyOrdersForShipToQuery } from "src/api";
import { EmergencyOrderProcessedCell, HighlightCell, SelectColumnFilter, TextColumnFilter } from "src/components";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { createTableModule, LongTextCell, TableNames } from "src/table";
import type { EmergencyOrderProductPackageListDto } from "src/types";
import { EmergencyOrderProductPackageStatusEnum } from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter, enumToLocalizedOptions, getTranslatedEnumValue } from "src/utils";

const columnHelper = createColumnHelper<EmergencyOrderProductPackageListDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("requestedOn", {
        id: "requestedOn",
        header: t("shipToDetail.emergencyOrders.table.requestedOn") as string,
        enableColumnFilter: false,
        cell: (cellContext) => dateTimeFormatter(cellContext.getValue(), DATE_TIME_FORMAT),
        meta: {
            collapse: true,
            noWrap: true,
        },
    }),
    columnHelper.accessor("status", {
        id: "status",
        header: t("shipToDetail.emergencyOrders.table.status") as string,
        cell: (cellContext) => getTranslatedEnumValue("EmergencyOrderProductPackageStatusEnum", cellContext.getValue()),
        meta: {
            width: 60,
            minWidth: 105,
            options: enumToLocalizedOptions("EmergencyOrderProductPackageStatusEnum", EmergencyOrderProductPackageStatusEnum),
            filterComponent: SelectColumnFilter,
        },
    }),
    columnHelper.accessor("productPackageName", {
        id: "productPackageName",
        header: t("shipToDetail.emergencyOrders.table.name") as string,
        meta: {
            width: 400,
            minWidth: 200,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("tierNumber", {
        id: "tierNumber",
        header: t("shipToDetail.emergencyOrders.table.tierNumber") as string,
        enableColumnFilter: false,
        cell: ({ row }) => (
            <HighlightCell highlight={!row.original.isDefaultTier}>
                {row.original.tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : row.original.tierNumber}
            </HighlightCell>
        ),
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("dailyLimit", {
        id: "dailyLimit",
        header: t("shipToDetail.emergencyOrders.table.dailyLimit") as string,
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("monthlyLimit", {
        id: "monthlyLimit",
        header: t("shipToDetail.emergencyOrders.table.monthlyLimit") as string,
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("monthlyVolumeAdjustment", {
        id: "monthlyVolumeAdjustment",
        header: t("shipToDetail.emergencyOrders.table.monthlyVolumeAdjustment") as string,
        enableColumnFilter: false,
        cell: ({ row }) => (
            <div>
                {row.original.status === EmergencyOrderProductPackageStatusEnum.Waiting
                    ? row.original.monthlyVolumeAdjustment
                    : row.original.monthlyVolumeAdjustmentBeforeProcess}
            </div>
        ),
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("requestedAmount", {
        id: "requestedAmount",
        header: t("shipToDetail.emergencyOrders.table.requestedAmount") as string,
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("approvedThisMonth", {
        id: "approvedThisMonth",
        header: t("shipToDetail.emergencyOrders.table.processed.header") as string,
        enableColumnFilter: false,
        cell: ({ row }) => <EmergencyOrderProcessedCell row={row.original} />,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("orderReason", {
        id: "orderReason",
        header: t("shipToDetail.emergencyOrders.table.orderReason") as string,
        enableColumnFilter: false,
        cell: (cellContext) => <LongTextCell text={cellContext.getValue()} charLimit={10} />,
        meta: {
            minWidth: 100,
        },
    }),
];

export const tableModule = createTableModule<EmergencyOrderProductPackageListDto>(
    TableNames.shipToEmergencyOrders,
    useGetEmergencyOrdersForShipToQuery,
    createColumns,
);
