import type { Row } from "@tanstack/react-table";

import { Color } from "src/theme";
import type { PhoenixInterfaceOperationDto } from "src/types";
import { PhoenixInterfaceOperationDtoStatusEnum } from "src/types";
import { EMPTY_OBJECT } from "src/utils";

export const overrideInterfaceTableRowProps = (row: Row<PhoenixInterfaceOperationDto>): Record<string, unknown> => {
    if (row.original.status === PhoenixInterfaceOperationDtoStatusEnum.Error) {
        return { $backgroundColor: Color.primary100 };
    }
    if (row.original.status === PhoenixInterfaceOperationDtoStatusEnum.InProgress) {
        return { $backgroundColor: Color.warning50 };
    }
    return EMPTY_OBJECT;
};
