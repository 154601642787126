import type { ChangeEvent, FC } from "react";

import { Color, IconSizes } from "src/theme";
import type { ColumnFilterProps } from "src/types";
import { TextInput, TextInputRightSection } from "../Form";
import { Icon } from "../Icons";
import { TABLE_FILTER_HEIGHT } from "./constants";

export const TextColumnFilter: FC<ColumnFilterProps> = ({ columnId, columnFilter, setColumnFilter }) => {
    const filterValue = columnFilter?.value;

    return (
        <TextInput
            icon={<Icon.Search color={Color.neutral500} size={IconSizes.s} />}
            value={filterValue || ""}
            fontSize="1.2rem"
            withoutBorder
            withoutErrorPlaceholder
            height={TABLE_FILTER_HEIGHT}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setColumnFilter({ id: columnId, value: event.currentTarget.value || "" });
            }}
            rightSection={
                filterValue !== undefined && (
                    <TextInputRightSection onClick={() => setColumnFilter(undefined)}>
                        <Icon.Close color={Color.supportNavy500} size={IconSizes.s} />
                    </TextInputRightSection>
                )
            }
        />
    );
};
