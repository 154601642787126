import type { Row } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { ReactNode } from "react";
import type { NavigateFunction } from "react-router-dom";

import { ActionIcon, Icon, PackagePlusIcon, TableAction } from "src/components";
import { createSearchParamsAsString, routes } from "src/routes";
import { createFiltersSearchParam, ICON_TABLE_SIZE, TableNames } from "src/table";
import { Color } from "src/theme";
import {
    type ExpandTableProductType,
    type ProductBrandDto,
    type ProductPackageDto,
    isProductBrandDto,
    isProductPackageDto,
} from "src/types";
import { EMPTY_OBJECT, noop } from "src/utils";

export const createActionColumn =
    (
        t: TFunction,
        navigate: NavigateFunction,
        openProductBrandFormModal: (productBrand: ProductBrandDto) => void,
        openProductPackageFormModal: (productBrand: ProductBrandDto, productPackage?: ProductPackageDto) => void,
    ) =>
    (row: Row<ExpandTableProductType>): ReactNode => {
        const productBrand = isProductBrandDto(row.original) ? row.original : row.getParentRow()?.original;
        const productPackage = isProductPackageDto(row.original) ? row.original : undefined;

        const isProductBrandArchived = productBrand?.archived;

        const productBrandIconColors: Color = isProductBrandArchived ? Color.supportGraphite300 : Color.primary600;
        const productPackageIconColors: Color = isProductBrandArchived ? Color.supportNavy500 : Color.primary600;

        return (
            <TableAction>
                {!row.getParentRow() && productBrand ? (
                    <>
                        <ActionIcon
                            tooltip={t("products.updateBrandTooltip")}
                            disabled={isProductBrandArchived}
                            onClick={() => openProductBrandFormModal(productBrand)}
                            color={Color.supportNavy100}
                        >
                            <Icon.Edit size={ICON_TABLE_SIZE} color={productBrandIconColors} filled />
                        </ActionIcon>
                        <ActionIcon
                            tooltip={t("products.createPackageTooltip")}
                            disabled={isProductBrandArchived}
                            onClick={() => openProductPackageFormModal(productBrand)}
                            color={Color.supportNavy100}
                        >
                            <PackagePlusIcon color={productBrandIconColors} />
                        </ActionIcon>
                    </>
                ) : (
                    <>
                        <ActionIcon
                            disabled={isProductBrandArchived}
                            onClick={productBrand ? () => openProductPackageFormModal(productBrand, productPackage) : noop}
                            color={Color.accent100}
                            tooltip={t("products.updatePackageTooltip")}
                        >
                            <Icon.Edit size={ICON_TABLE_SIZE} color={productPackageIconColors} />
                        </ActionIcon>
                        {productPackage && (
                            <>
                                <ActionIcon
                                    tooltip={t("products.goToPriceListTooltip")}
                                    disabled={isProductBrandArchived || !productPackage.active}
                                    onClick={() =>
                                        navigate(
                                            `${routes.prices.path}?${createSearchParamsAsString({
                                                [createFiltersSearchParam(TableNames.productPrice)]: { name: productPackage.name },
                                            })}`,
                                        )
                                    }
                                    color={Color.accent100}
                                >
                                    <Icon.Money size={ICON_TABLE_SIZE} color={productPackageIconColors} />
                                </ActionIcon>
                                <ActionIcon
                                    tooltip={t("products.goToSupplyLimitTooltip")}
                                    disabled={isProductBrandArchived || !productPackage.active}
                                    onClick={() =>
                                        navigate(
                                            routes.supplyLimitsDetail.fillPathParams({ productPackageId: productPackage.id.toString() }),
                                        )
                                    }
                                    color={Color.accent100}
                                >
                                    <Icon.VehicleTruck size={ICON_TABLE_SIZE} color={productPackageIconColors} />
                                </ActionIcon>
                            </>
                        )}
                    </>
                )}
            </TableAction>
        );
    };

export const overrideRowProps = (row: Row<ExpandTableProductType>): Record<string, unknown> => {
    if (row.getCanExpand()) {
        if (row.original.archived) {
            return { $backgroundColor: Color.supportNavy50 };
        }
        if (!row.original.active) {
            return { $disabled: true };
        }
    } else if (row.original.archived || !row.original.active) {
        return { $disabled: true };
    }

    return EMPTY_OBJECT;
};
