import { useGetGroupedSupplyLimitSuggestionsQuery } from "src/api";
import { createTableModule, TableNames } from "src/table";
import type { ExpandTableSupplySuggestionType } from "src/types";
import { createGroupedColumns } from "./createGroupedColumns";

export const groupedTableModule = createTableModule<ExpandTableSupplySuggestionType>(
    TableNames.groupedSupplyLimitSuggestions,
    useGetGroupedSupplyLimitSuggestionsQuery,
    createGroupedColumns,
);
