import type { FC } from "react";

import { useRefuseEmergencyOrdersMutation } from "src/api";
import { Button, Modal, ModalButtonLayout, Textarea, TextareaProps } from "src/components";
import { Form, InputField } from "src/form";
import { useModal } from "src/modal";
import { useTranslation } from "src/translations";
import type { RefuseEmergencyOrderDto } from "src/types";
import { useAppDispatch } from "src/utils";
import { REFUSE_EMERGENCY_ORDERS_MODAL } from "./constants";
import { groupedTableModule } from "./groupedTable";
import { tableModule } from "./table";

type RefuseFormProps = {
    selectedIds: number[];
};

export const RefuseForm: FC<RefuseFormProps> = ({ selectedIds }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isModalOpened, openModal, closeModal] = useModal(REFUSE_EMERGENCY_ORDERS_MODAL);
    const [refuse] = useRefuseEmergencyOrdersMutation();

    const onSuccess = () => {
        dispatch(tableModule.resetTableSelectionAction);
        dispatch(groupedTableModule.resetTableSelectionAction);
        closeModal();
    };

    return (
        <>
            <Button disabled={selectedIds.length === 0} variant="outline" type="button" onClick={openModal}>
                {t("dashboard.emergencyOrders.refuseSelected")}
            </Button>
            <Modal title={t("dashboard.emergencyOrders.refuseModal.title")} opened={isModalOpened} onClose={closeModal}>
                <Form<RefuseEmergencyOrderDto>
                    initialValues={{ emergencyOrderProductPackageIds: selectedIds, refusalReason: "" }}
                    onSubmit={refuse}
                    onSuccess={onSuccess}
                    loadingMessage={t("dashboard.emergencyOrders.loadingRefuseBulkMessage")}
                    successMessage={t("dashboard.emergencyOrders.successRefuseBulkMessage")}
                >
                    {({ dirty }) => (
                        <>
                            <InputField<TextareaProps["value"], TextareaProps>
                                name="refusalReason"
                                input={Textarea}
                                inputProps={{
                                    label: t("dashboard.emergencyOrders.refusalReasonLabel"),
                                }}
                                required
                            />
                            <ModalButtonLayout>
                                <Button type="submit" disabled={!dirty}>
                                    {t("dashboard.emergencyOrders.refuseSelected")}
                                </Button>
                                <Button type="button" onClick={closeModal} variant="outline">
                                    {t("common.cancel")}
                                </Button>
                            </ModalButtonLayout>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};
