import type { FC } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Icon, TableWithButtonsLayout } from "src/components";
import { PageLayout } from "src/layout";
import { useModalControls } from "src/modal";
import { routes } from "src/routes";
import { Color, IconSizes } from "src/theme";
import { useTranslation } from "src/translations";
import type { ProductBrandDto, ProductPackageDto } from "src/types";
import { FALSE_STRING, TRUE_STRING } from "src/utils";
import { PRODUCT_BRAND_MODAL_ID, PRODUCT_PACKAGE_MODAL_ID } from "./constants";
import { ProductBrandForm, ProductPackageForm } from "./forms";
import { tableModule } from "./table";
import { createActionColumn, overrideRowProps } from "./utils";

export const Products: FC = () => {
    const [openProductBrandModal] = useModalControls(PRODUCT_BRAND_MODAL_ID);
    const [openProductPackageModal] = useModalControls(PRODUCT_PACKAGE_MODAL_ID);
    const navigate = useNavigate();
    const [productBrandForPackage, setProductBrandForPackage] = useState<ProductBrandDto | undefined>(undefined);
    const [productBrandForEdit, setProductBrandForEdit] = useState<ProductBrandDto | undefined>(undefined);
    const [productPackageForEdit, setProductPackageForEdit] = useState<ProductPackageDto | undefined>(undefined);
    const { t } = useTranslation();

    const openProductBrandFormModal = (productBrand?: ProductBrandDto) => {
        setProductBrandForEdit(productBrand);
        openProductBrandModal();
    };

    const openProductPackageFormModal = (productBrand: ProductBrandDto, productPackage?: ProductPackageDto) => {
        setProductBrandForPackage(productBrand);
        setProductPackageForEdit(productPackage);
        openProductPackageModal();
    };

    return (
        <>
            <ProductBrandForm productBrandForEdit={productBrandForEdit} />
            <ProductPackageForm productPackageForEdit={productPackageForEdit} productBrand={productBrandForPackage} />
            <PageLayout breadcrumbs={[{ name: t("products.name"), route: routes.products.path, icon: Icon.Box }]}>
                <TableWithButtonsLayout
                    buttons={
                        <Button
                            rightIcon={<Icon.Add size={IconSizes.s} color={Color.neutral50} filled />}
                            onClick={() => openProductBrandFormModal()}
                        >
                            {t("products.create")}
                        </Button>
                    }
                    table={
                        <tableModule.Container
                            overrideRowProps={overrideRowProps}
                            isExpandable
                            actions={createActionColumn(t, navigate, openProductBrandFormModal, openProductPackageFormModal)}
                            defaultTableConfig={{ initialFilters: { active: TRUE_STRING, archived: FALSE_STRING } }}
                        />
                    }
                />
            </PageLayout>
        </>
    );
};
