import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetCustomersToUpdateQuery } from "src/api";
import { SelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { createTableModule, TableNames } from "src/table";
import { Color } from "src/theme";
import type { CustomerListDto } from "src/types";
import { CustomerCategoryEnum, CustomerTypeEnum } from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue, zipFormatter } from "src/utils";
import { customerCategoryFormatter } from "../../utils";

const columnHelper = createColumnHelper<CustomerListDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("category", {
        id: "category",
        header: t("customers.table.category") as string,
        cell: (cellContext) => customerCategoryFormatter(t, cellContext.getValue()),
        meta: {
            collapse: true,
            minWidth: 65,
            noWrap: true,
            align: "center",
            filterComponent: SelectColumnFilter,
            options: enumToLocalizedOptions("customerCategory", CustomerCategoryEnum),
        },
    }),
    columnHelper.accessor("name", {
        id: "name",
        header: t("customers.table.name") as string,
        cell: (cellContext) => (
            <TableLink
                color={Color.primary600}
                weight={700}
                to={routes.customerDetail.fillPathParams({
                    customerId: cellContext.row.original.id.toString(),
                })}
            >
                {cellContext.getValue()}
            </TableLink>
        ),
        meta: {
            minWidth: 120,
            width: 350,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("type", {
        id: "type",
        header: t("customers.table.type") as string,
        cell: (cellContext) => getTranslatedEnumValue("CustomerTypeEnum", cellContext.getValue()),
        meta: {
            minWidth: 100,
            filterComponent: SelectColumnFilter,
            options: enumToLocalizedOptions("CustomerTypeEnum", CustomerTypeEnum),
        },
    }),
    columnHelper.accessor("acn", {
        id: "acn",
        header: t("customers.table.acn") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("street", {
        id: "street",
        header: t("customers.table.address") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("city", {
        id: "city",
        header: t("customers.table.city") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("zip", {
        id: "zip",
        header: t("customers.table.zip") as string,
        cell: (cellContext) => zipFormatter(cellContext.getValue()),
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("tin", {
        id: "tin",
        header: t("customers.table.tin") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("payerName", {
        id: "payerName",
        header: t("customers.table.payerName") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("payerAcn", {
        id: "payerAcn",
        header: t("customers.table.payerAcn") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
];

export const tableModule = createTableModule<CustomerListDto>(TableNames.customersUpdated, useGetCustomersToUpdateQuery, createColumns);
