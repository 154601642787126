import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetSpecialPricesQuery } from "src/api";
import { TextColumnFilter } from "src/components";
import { createTableModule, LongTextCell, ParentRow, TableNames } from "src/table";
import {
    type ExpandTableSpecialPricesType,
    type ProductBrandWithSpecialPricesDto,
    type SpecialPriceDto,
    isProductBrandWithSpecialPricesDto,
    isSpecialPriceDto,
} from "src/types";
import { currencyFormatter, dateTimeFormatter, getTranslatedEnumValue, percentageFormatter } from "src/utils";

const columnHelper = createColumnHelper<ProductBrandWithSpecialPricesDto | SpecialPriceDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("specialPriceSource", {
        id: "specialPriceSource",
        header: t("specialPrices.table.specialPriceSource") as string,
        enableColumnFilter: false,
        cell: ({ row }) => isSpecialPriceDto(row.original) && getTranslatedEnumValue("specialPriceSource", row.original.specialPriceSource),
        meta: {
            collapse: true,
        },
    }),
    columnHelper.accessor("productPackageName", {
        id: "productPackageName",
        header: t("specialPrices.table.name") as string,
        cell: ({ row }) =>
            isProductBrandWithSpecialPricesDto(row.original) ? <ParentRow>{row.original.name}</ParentRow> : row.original.productPackageName,
        meta: { width: 350, minWidth: 150, filterComponent: TextColumnFilter, expandedRowColspan: 3 },
    }),
    columnHelper.accessor("specialPriceInclMargin", {
        id: "specialPriceInclMargin",
        enableColumnFilter: false,
        header: t("specialPrices.table.specialPrice") as string,
        cell: ({ row }) => isSpecialPriceDto(row.original) && currencyFormatter(row.original.specialPriceInclMargin),
        meta: { minWidth: 100, align: "end" },
    }),
    columnHelper.accessor("validFrom", {
        id: "validFrom",
        enableColumnFilter: false,
        header: t("specialPrices.table.from") as string,
        cell: ({ row }) => isSpecialPriceDto(row.original) && dateTimeFormatter(row.original.validFrom),
        meta: { minWidth: 100 },
    }),
    columnHelper.accessor("validTo", {
        id: "validTo",
        enableColumnFilter: false,
        header: t("specialPrices.table.to") as string,
        cell: ({ row }) => isSpecialPriceDto(row.original) && dateTimeFormatter(row.original.validTo),
        meta: { minWidth: 100 },
    }),
    columnHelper.accessor("specialPriceMargin", {
        id: "specialPriceMargin",
        enableColumnFilter: false,
        header: t("specialPrices.table.margin") as string,
        cell: ({ row }) => isSpecialPriceDto(row.original) && percentageFormatter(row.original.specialPriceMargin),
        meta: { minWidth: 100, align: "end" },
    }),
    columnHelper.accessor("contractId", {
        id: "contractId",
        enableColumnFilter: false,
        header: t("specialPrices.table.contract") as string,
        meta: { minWidth: 100, align: "end" },
    }),
    columnHelper.accessor("createdBy", {
        id: "createdBy",
        enableColumnFilter: false,
        header: t("specialPrices.table.createdBy") as string,
        meta: { minWidth: 100, collapse: true },
    }),
    columnHelper.accessor("azNote", {
        id: "azNote",
        enableColumnFilter: false,
        header: t("specialPrices.table.note") as string,
        cell: ({ row }) => isSpecialPriceDto(row.original) && <LongTextCell text={row.original.azNote ?? ""} charLimit={8} />,
        meta: { minWidth: 100, collapse: true },
    }),
    columnHelper.accessor("active", {
        id: "active",
        enableColumnFilter: false,
        header: t("specialPrices.table.status") as string,
        cell: ({ row }) => {
            const specialPrice = isSpecialPriceDto(row.original) ? row.original : undefined;
            if (!specialPrice) {
                return null;
            }

            return specialPrice.active ? t("specialPrices.table.active.true") : t("specialPrices.table.active.false");
        },
        meta: { minWidth: 100, collapse: true },
    }),
];

export const tableModule = createTableModule<ExpandTableSpecialPricesType>(
    TableNames.shipToSpecialPrices,
    useGetSpecialPricesQuery,
    createColumns,
);
