import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetPayersQuery } from "src/api";
import { sortedCustomerCredibilityEnumToLocalizedOptions } from "src/app/customers/containers/ChangeCredibilityForm";
import { SelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { createTableModule, TableNames } from "src/table";
import { Color } from "src/theme";
import { type CustomerDto, type ExpandTableCustomerType, CustomerStatusEnum, CustomerTypeEnum } from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue, zipFormatter } from "src/utils";
import { customerCategoryFormatter } from "../../utils";

const columnHelper = createColumnHelper<CustomerDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("category", {
        id: "category",
        header: t("customers.table.category") as string,
        cell: (cellContext) => customerCategoryFormatter(t, cellContext.getValue()),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            noWrap: true,
            align: "center",
        },
    }),
    columnHelper.accessor("name", {
        id: "name",
        header: t("customers.table.name") as string,
        cell: (cellContext) =>
            cellContext.row.getCanExpand() ? (
                <TableLink
                    color={Color.primary600}
                    weight={700}
                    to={`${routes.customerDetail.fillPathParams({
                        customerId: cellContext.row.original.id.toString(),
                    })}`}
                >
                    {cellContext.row.original.name}
                </TableLink>
            ) : (
                <TableLink
                    to={routes.customerDetail.fillPathParams({
                        customerId: cellContext.row.original.id.toString(),
                    })}
                >
                    {cellContext.getValue()}
                </TableLink>
            ),
        meta: {
            width: 350,
            minWidth: 120,
            filterComponent: TextColumnFilter,

            expandedRowColspan: 2,
        },
    }),
    columnHelper.accessor("type", {
        id: "type",
        header: t("customers.table.type") as string,
        cell: (cellContext) => getTranslatedEnumValue("CustomerTypeEnum", cellContext.getValue()),
        meta: {
            filterComponent: SelectColumnFilter,
            options: enumToLocalizedOptions("CustomerTypeEnum", CustomerTypeEnum),
            minWidth: 100,
        },
    }),
    columnHelper.accessor("acn", {
        id: "acn",
        header: t("customers.table.acn") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("street", {
        id: "street",
        header: t("customers.table.address") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("city", {
        id: "city",
        header: t("customers.table.city") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("zip", {
        id: "zip",
        header: t("customers.table.zip") as string,
        cell: (cellContext) => zipFormatter(cellContext.getValue()),
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("tin", {
        id: "tin",
        header: t("customers.table.tin") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("credibility", {
        id: "credibility",
        header: t("customers.table.credibility") as string,
        cell: (cellContext) => getTranslatedEnumValue("customerCredibility", cellContext.getValue()),
        meta: {
            minWidth: 120,
            width: 120,
            filterComponent: SelectColumnFilter,
            options: sortedCustomerCredibilityEnumToLocalizedOptions(),
        },
    }),
    columnHelper.accessor("status", {
        id: "status",
        header: t("customers.table.status") as string,
        cell: (cellContext) => getTranslatedEnumValue("customerStatus", cellContext.getValue()),
        meta: {
            minWidth: 100,
            collapse: true,
            filterComponent: SelectColumnFilter,
            options: enumToLocalizedOptions("customerStatus", CustomerStatusEnum, [CustomerStatusEnum.New]),
        },
    }),
];

export const tableModule = createTableModule<ExpandTableCustomerType>(TableNames.customersAll, useGetPayersQuery, createColumns);
