import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "i18next";

import { sortedCustomerCredibilityEnumToLocalizedOptions } from "src/app/customers/containers/ChangeCredibilityForm";
import { EmergencyOrderProcessedCell, HighlightCell, SelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import type { EmergencyOrderProductPackageListDto } from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter } from "src/utils";

const columnHelper = createColumnHelper<EmergencyOrderProductPackageListDto>();

export const createColumns = (t: TFunction) => [
    columnHelper.accessor("requestedOn", {
        id: "requestedOn",
        header: t("dashboard.emergencyOrders.table.requestedOn") as string,
        cell: (info) => dateTimeFormatter(info.getValue(), DATE_TIME_FORMAT),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            minWidth: 140,
        },
    }),
    columnHelper.accessor("customerName", {
        id: "customerName",
        header: t("dashboard.emergencyOrders.table.requestedFor") as string,
        cell: (cellContext) => (
            <TableLink to={routes.customerDetail.fillPathParams({ customerId: cellContext.row.original.customerId.toString() })}>
                {cellContext.getValue()}
            </TableLink>
        ),
        meta: {
            width: 250,
            minWidth: 120,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("customerAcn", {
        id: "customerAcn",
        header: t("dashboard.emergencyOrders.table.acn"),
        meta: {
            width: 150,
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("productPackageName", {
        header: t("dashboard.emergencyOrders.table.product"),
        meta: {
            width: 150,
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("tierNumber", {
        id: "tierNumber",
        header: t("dashboard.emergencyOrders.table.tierNumber"),

        cell: (cellContext) => (
            <HighlightCell highlight={!cellContext.row.original.isDefaultTier}>
                {cellContext.row.original.tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : cellContext.getValue()}
            </HighlightCell>
        ),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("dailyLimit", {
        id: "dailyLimit",
        header: t("dashboard.emergencyOrders.table.dailyLimit"),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("monthlyLimit", {
        id: "monthlyLimit",
        header: t("dashboard.emergencyOrders.table.monthlyLimit"),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("monthlyVolumeAdjustment", {
        id: "monthlyVolumeAdjustment",
        header: t("dashboard.emergencyOrders.table.monthlyVolumeAdjustment"),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("requestedAmount", {
        id: "requestedAmount",
        header: t("dashboard.emergencyOrders.table.requestedAmount"),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("approvedThisMonth", {
        id: "approvedThisMonth",
        header: t("dashboard.emergencyOrders.table.processed.header"),
        enableColumnFilter: false,
        cell: ({ row }) => <EmergencyOrderProcessedCell row={row.original} />,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("orderReason", {
        id: "orderReason",
        header: t("dashboard.emergencyOrders.table.description"),
        enableColumnFilter: false,
        meta: {
            minWidth: 120,
        },
    }),
    columnHelper.accessor("customerCredibility", {
        id: "customerCredibility",
        header: t("dashboard.emergencyOrders.table.credibility"),
        meta: {
            minWidth: 120,
            width: 120,
            filterComponent: SelectColumnFilter,
            options: sortedCustomerCredibilityEnumToLocalizedOptions(),
        },
    }),
];
