import type { FC } from "react";

import type { ColumnFilterProps } from "src/types";
import { isEmpty } from "src/utils";
import { NumberInput } from "../Form";
import { TABLE_FILTER_HEIGHT } from "./constants";

export const NumberColumnFilter: FC<ColumnFilterProps> = ({ columnId, columnFilter, setColumnFilter }) => {
    const filterValue = columnFilter?.value;
    const numberValue = !isEmpty(filterValue) && typeof filterValue === "string" ? parseFloat(filterValue) : filterValue;

    return (
        <NumberInput
            value={isEmpty(numberValue) ? undefined : numberValue}
            height={TABLE_FILTER_HEIGHT}
            noClampOnBlur
            fontSize="1.2rem"
            withoutBorder
            hideControls
            withoutErrorPlaceholder
            onChange={(value: number | undefined) => {
                // If old value is same as new value, we don't need to call setFilter
                // this will fix the issue when user click outside the input and rows are expanded
                // because onChange is called on blur, and you cannot turn it off
                if (numberValue !== value) {
                    setColumnFilter({ id: columnId, value });
                }
            }}
        />
    );
};
