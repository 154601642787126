import type { Row } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { ReactNode } from "react";

import { ActionIcon, Button, Confirm, Icon, TableAction } from "src/components";
import { ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import type { CustomerListDto } from "src/types";

export const createActionColumn =
    (t: TFunction, setSelectedCustomerId: (customerId: number) => void, openModal: () => void) =>
    ({ original }: Row<CustomerListDto>): ReactNode => {
        const onOpenModal = (): void => {
            setSelectedCustomerId(original.id);
            openModal();
        };
        return (
            <TableAction>
                <ActionIcon tooltip={t("customers.updated.changes.tooltip")} onClick={onOpenModal} color={Color.accent100}>
                    <Icon.DocumentSearch size={ICON_TABLE_SIZE} color={Color.supportNavy500} />
                </ActionIcon>
            </TableAction>
        );
    };

export const createActionsForSelection =
    (t: TFunction, updateCustomers: (customerIds: Array<number>) => void) => (_: boolean, selectedRowsIds: number[]) => {
        return (
            <Confirm
                title={t("customers.updated.confirm.title")}
                content={t("customers.updated.confirm.content")}
                onConfirm={() => updateCustomers(selectedRowsIds)}
            >
                {(open) => (
                    <Button disabled={selectedRowsIds.length === 0} type="button" onClick={open}>
                        {t("customers.updated.updateSelected")}
                    </Button>
                )}
            </Confirm>
        );
    };
