import type { ColumnFilter } from "@tanstack/react-table";
import type { TFunction } from "i18next";

import { Button, Confirm } from "src/components";
import type { CustomerActionRequestDto } from "src/types";
import { getParamsForBlockUnblockTables } from "../../utils";

export const createActionsForSelection =
    (t: TFunction, unblockPayersMutation: (value: CustomerActionRequestDto) => void) =>
    (isAllRowsSelected: boolean, selectedRowIds: number[], filters: ColumnFilter[]) => {
        const onClick = () => {
            unblockPayersMutation(getParamsForBlockUnblockTables(isAllRowsSelected, selectedRowIds, filters));
        };
        return (
            <Confirm title={t("customers.unblock.confirm.title")} content={t("customers.unblock.confirm.content")} onConfirm={onClick}>
                {(open) => (
                    <Button disabled={!isAllRowsSelected && Object.keys(selectedRowIds).length === 0} type="button" onClick={open}>
                        {t("customers.unblock.unblockSelected")}
                    </Button>
                )}
            </Confirm>
        );
    };
