import { useEventListener, useMergedRef, useWindowEvent } from "@mantine/hooks";
import type { ColumnDef, ColumnFilter, ColumnMeta, RowSelectionState } from "@tanstack/react-table";
import type { Dispatch, SetStateAction } from "react";
import { RefObject, useCallback, useLayoutEffect, useRef, useState } from "react";

import { ActionIcon, Icon } from "src/components";
import { Color } from "src/theme";
import type { AnyRecord } from "src/types";
import { HeaderTableCheckbox, TableCheckbox } from "../components";
import { ACTION_COLUMN_ID, EXPAND_COLUMN_ID, ICON_TABLE_SIZE, PARENT_ROW_PREFIX, SELECT_COLUMN_ID } from "../constants";
import type { ColumnDefCellProps, ColumnDefHeaderProps } from "../types";

export const collectCellProps = <TData, TValue>(metaData?: ColumnMeta<TData, TValue>) => ({
    align: metaData?.align,
    className: metaData?.collapse ? "collapse" : "",
    noWrap: metaData?.noWrap,
});

export const addSpecialColumns = <TData, TValue>(
    columns: ColumnDef<TData, TValue>[],
    isSelectable: boolean,
    isAllRowsSelected: boolean,
    setIsAllRowsSelected: Dispatch<SetStateAction<boolean>>,
    setRowSelection: Dispatch<SetStateAction<RowSelectionState>>,
    isExpandable: boolean,
): ColumnDef<TData, TValue>[] => {
    // Add select to columns
    const columnsWithSelect = isSelectable
        ? [
              {
                  header: ({ table }: ColumnDefHeaderProps<TData, TValue>) => {
                      const onChange = () => {
                          if (isAllRowsSelected) {
                              setIsAllRowsSelected(false);
                              setRowSelection({});

                              table.toggleAllRowsSelected();
                          }

                          table.toggleAllRowsSelected();
                      };

                      const { flatRows } = table.getRowModel();
                      const { rowSelection } = table.getState();

                      // Rows can have sub rows, so we need to count them all
                      // Also we need to filter parent_ids because the expand rows rowSelection state doesn't behave deterministically
                      const subRowsIds = flatRows.filter((row) => !row.id.startsWith(PARENT_ROW_PREFIX)).map((row) => row.id);
                      const pageSubRowsSelectionIds = Object.keys(rowSelection).filter(
                          (key) => !key.startsWith(PARENT_ROW_PREFIX) && subRowsIds.includes(key),
                      );

                      const isIndeterminate =
                          flatRows.map((row) => row.id).some((id: string) => Object.keys(rowSelection).includes(id)) &&
                          subRowsIds.length !== pageSubRowsSelectionIds.length;

                      const isChecked = pageSubRowsSelectionIds.length === subRowsIds.length && subRowsIds.length > 0;

                      return (
                          <HeaderTableCheckbox
                              checked={isAllRowsSelected ? true : isChecked}
                              indeterminate={isAllRowsSelected ? false : isIndeterminate}
                              onChange={onChange}
                          />
                      );
                  },
                  cell: ({ row, table }: ColumnDefCellProps<TData, TValue>) => {
                      const onChange = () => {
                          if (isAllRowsSelected) {
                              setIsAllRowsSelected(false);
                              // We need to handle also possible sub rows
                              const { flatRows } = table.getRowModel();

                              const selectedRows = flatRows.filter((r) => r.id !== row.id);
                              setRowSelection(selectedRows.reduce((all, r) => ({ ...all, [r.id]: true }), {}));
                          }

                          row.toggleSelected();
                      };

                      const isParentRowChecked = row.subRows.every((r) => r.getIsSelected());
                      const isRowWithExpandStateChecked = row.getCanExpand() ? isParentRowChecked : row.getIsSelected();

                      const isExpandedRowIndeterminate = !isParentRowChecked && row.subRows.some((r) => r.getIsSelected());

                      return (
                          <TableCheckbox
                              indeterminate={!isExpandable ? false : isExpandedRowIndeterminate}
                              checked={isAllRowsSelected ? true : !isExpandable ? row.getIsSelected() : isRowWithExpandStateChecked}
                              disabled={!row.getCanSelect()}
                              onChange={onChange}
                          />
                      );
                  },
                  id: SELECT_COLUMN_ID,
                  enableColumnFilter: false,
                  meta: {
                      collapse: true,
                      headerVerticalAlign: "bottom",
                      align: "center",
                  },
              },
          ]
        : [];

    // Add expand to columns
    const columnsWithExpanded = isExpandable
        ? [
              {
                  id: EXPAND_COLUMN_ID,
                  enableColumnFilter: true,
                  meta: {
                      collapse: true,
                      headerVerticalAlign: "middle",
                      align: "center",
                  },
                  header: ({ table }: ColumnDefHeaderProps<TData, TValue>) => {
                      const toggleExpanded = () => {
                          if (table.getIsSomeRowsExpanded()) {
                              table.setExpanded({});
                          } else {
                              table.toggleAllRowsExpanded();
                          }
                      };
                      return (
                          table.getCanSomeRowsExpand() && (
                              <ActionIcon color={Color.primary700} onClick={() => toggleExpanded()}>
                                  <Icon.ChevronDoubleDown color={Color.neutral50} size={ICON_TABLE_SIZE} />
                              </ActionIcon>
                          )
                      );
                  },
                  cell: ({ row }: ColumnDefCellProps<TData, TValue>) => {
                      return (
                          row.getCanExpand() && (
                              <ActionIcon color={Color.supportNavy100} onClick={() => row.toggleExpanded()}>
                                  {row.getIsExpanded() ? (
                                      <Icon.ChevronUp color={Color.supportNavy500} size={ICON_TABLE_SIZE} />
                                  ) : (
                                      <Icon.ChevronDown color={Color.supportNavy500} size={ICON_TABLE_SIZE} />
                                  )}
                              </ActionIcon>
                          )
                      );
                  },
              },
          ]
        : [];

    // Add action to columns
    // Even if there aren't any actions, we still need to add it to the columns for reset button
    return [
        ...columnsWithExpanded,
        ...columnsWithSelect,
        ...columns,
        {
            header: "",
            id: ACTION_COLUMN_ID,
            enableColumnFilter: false,
            meta: {
                collapse: true,
                headerVerticalAlign: "bottom",
                align: "flex-end",
            },
        },
    ];
};

export const convertFiltersToFilterParams = (filters: Array<AnyRecord>): AnyRecord =>
    filters.reduce((all, { id, value }) => ({ ...all, [id]: value }), {});

export const convertFilterParamsToFilters = (filterParams?: AnyRecord): ColumnFilter[] =>
    filterParams
        ? Object.entries(filterParams).reduce(
              (all: Array<{ id: string; value: unknown }>, [key, value]) => [...all, { id: key, value }],
              [],
          )
        : [];

const TABLE_DECORATION_VISIBILITY_OFFSET = 10;

export const useTableDecoration = (): {
    tableRef: RefObject<HTMLTableElement>;
    wrapperRef: (node: unknown) => void;
    isLeftDecorationVisible: boolean;
    isRightDecorationVisible: boolean;
} => {
    const [isLeftDecorationVisible, setIsLeftDecorationVisible] = useState(false);
    const [isRightDecorationVisible, setIsRightDecorationVisible] = useState(false);
    const tableRef = useRef<HTMLTableElement>(null);
    const tableWrapperRef = useRef<HTMLDivElement>(null);

    const checkDecorationVisibility = useCallback(() => {
        const tableBounding = tableRef.current?.getBoundingClientRect();
        const wrapperBounding = tableWrapperRef.current?.getBoundingClientRect();
        if (tableBounding && wrapperBounding) {
            if (tableBounding.left > wrapperBounding.left - TABLE_DECORATION_VISIBILITY_OFFSET) {
                setIsLeftDecorationVisible(false);
            } else {
                setIsLeftDecorationVisible(true);
            }
            if (tableBounding.right < wrapperBounding.right + TABLE_DECORATION_VISIBILITY_OFFSET) {
                setIsRightDecorationVisible(false);
            } else {
                setIsRightDecorationVisible(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableRef.current, tableWrapperRef.current]);

    // layout effect is here because we are working with refs inside checkDecorationVisibility function
    useLayoutEffect(() => {
        checkDecorationVisibility();
    }, [checkDecorationVisibility]);

    const scrollRef = useEventListener("scroll", checkDecorationVisibility);
    useWindowEvent("resize", checkDecorationVisibility);

    const mergedRef = useMergedRef(tableWrapperRef, scrollRef);

    return {
        tableRef,
        wrapperRef: mergedRef,
        isLeftDecorationVisible,
        isRightDecorationVisible,
    };
};
