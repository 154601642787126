import type { FC } from "react";

import { CustomerStatusEnum } from "src/types";
import { tableModule } from "./table";
import { overrideRowProps } from "./utils";

export const All: FC = () => (
    <tableModule.Container
        overrideRowProps={overrideRowProps}
        isExpandable
        defaultTableConfig={{ initialFilters: { status: CustomerStatusEnum.Active } }}
    />
);
