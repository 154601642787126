import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetDataModificationLogQuery } from "src/api";
import { SelectColumnFilter, TextColumnFilter } from "src/components";
import { createTableModule, TableNames } from "src/table";
import type { TableDataModificationLogType } from "src/types";
import { DataModificationLogActionEnum, DataModificationLogTableEnum } from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter, enumToLocalizedOptions, getTranslatedEnumValue } from "src/utils";
import { ChangeValuesCell } from "../components";

const columnHelper = createColumnHelper<TableDataModificationLogType>();
const createColumns = (t: TFunction, tableName: string) => {
    return [
        columnHelper.accessor("timestamp", {
            id: "timestamp",
            header: t("payerDetail.dataModificationLog.table.timestamp") as string,
            cell: (cellContext) => dateTimeFormatter(cellContext.getValue(), DATE_TIME_FORMAT),
            enableColumnFilter: false,
            meta: {
                collapse: true,
                minWidth: 140,
            },
        }),
        columnHelper.accessor("userName", {
            id: "userName",
            header: t("payerDetail.dataModificationLog.table.user"),
            meta: {
                minWidth: 100,
                filterComponent: TextColumnFilter,
            },
        }),
        columnHelper.accessor("action", {
            id: "action",
            header: t("payerDetail.dataModificationLog.table.action"),
            cell: (cellContext) => getTranslatedEnumValue("DataModificationLogAction", cellContext.getValue()),
            meta: {
                collapse: true,
                minWidth: 100,
                options: enumToLocalizedOptions("DataModificationLogAction", DataModificationLogActionEnum),
                filterComponent: SelectColumnFilter,
            },
        }),
        columnHelper.accessor("table", {
            id: "table",
            header: t("payerDetail.dataModificationLog.table.table"),
            cell: (cellContext) => getTranslatedEnumValue("DataModificationLogTable", cellContext.getValue()),
            meta: {
                collapse: true,
                minWidth: 150,
                options: enumToLocalizedOptions(
                    "DataModificationLogTable",
                    DataModificationLogTableEnum,
                    tableName === TableNames.payerDataModificationLog
                        ? [
                              DataModificationLogTableEnum.CustomerSupplyLimitTable,
                              DataModificationLogTableEnum.EmergencyOrderAdjustmentTable,
                          ]
                        : [],
                ),
                filterComponent: SelectColumnFilter,
            },
        }),
        columnHelper.accessor("oldValues", {
            id: "oldValues",
            header: t("payerDetail.dataModificationLog.table.oldValues"),
            enableColumnFilter: false,
            cell: ({ row }) =>
                row.original.oldValues ? <ChangeValuesCell values={row.original.oldValues} columns={row.original.column} /> : null,
            meta: {
                minWidth: 200,
            },
        }),
        columnHelper.accessor("newValues", {
            id: "newValues",
            header: t("payerDetail.dataModificationLog.table.newValues"),
            enableColumnFilter: false,
            cell: ({ row }) =>
                row.original.newValues ? (
                    <ChangeValuesCell
                        values={row.original.newValues}
                        columns={row.original.column}
                        highlight={row.original.action !== DataModificationLogActionEnum.D}
                    />
                ) : null,
            meta: {
                minWidth: 200,
            },
        }),
    ];
};

export const payerDataModificationLogTableModule = createTableModule<TableDataModificationLogType>(
    TableNames.payerDataModificationLog,
    useGetDataModificationLogQuery,
    createColumns,
);

export const shipToDataModificationLogTableModule = createTableModule<TableDataModificationLogType>(
    TableNames.shipToDataModificationLog,
    useGetDataModificationLogQuery,
    createColumns,
);
