import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetShipToForSupplyLimitQuery } from "src/api";
import { BranchColumnFilter, TierColumnFilter } from "src/asyncFilters";
import { SelectColumnFilter, TextColumnFilter } from "src/components";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { createTableModule, TableNames } from "src/table";
import { type ShipToForSupplyLimitDto, CustomerStatusEnum } from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue } from "src/utils";

const columnHelper = createColumnHelper<ShipToForSupplyLimitDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("supplyLimitDto", {
        id: "supplyLimitDto",
        header: t("supplyLimits.bulkEdit.tierNumber") as string,
        cell: ({ row }) =>
            row.original.supplyLimitDto?.tierNumber === UNLIMITED_TIER_NUMBER
                ? t("supplyLimits.bulkEdit.unlimited")
                : row.original.supplyLimitDto?.tierNumber,
        meta: {
            minWidth: 100,
            collapse: true,
            filterComponent: TierColumnFilter,
        },
    }),
    columnHelper.accessor("name", {
        id: "name",
        header: t("supplyLimits.bulkEdit.name") as string,
        meta: {
            width: 350,
            minWidth: 120,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("branch", {
        id: "branch",
        header: t("supplyLimits.bulkEdit.branch") as string,
        cell: ({ row }) => `${row.original.branch?.code} - ${row.original.branch?.name}`,
        meta: {
            width: 250,
            minWidth: 120,
            filterComponent: BranchColumnFilter,
        },
    }),
    columnHelper.accessor("acn", {
        id: "acn",
        header: t("supplyLimits.bulkEdit.acn") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("payerName", {
        id: "payerName",
        header: t("supplyLimits.bulkEdit.payerName") as string,
        meta: {
            minWidth: 120,
            width: 300,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("payerAcn", {
        id: "payerAcn",
        header: t("supplyLimits.bulkEdit.payerAcn") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("status", {
        id: "status",
        header: t("supplyLimits.bulkEdit.status") as string,
        cell: (cellContext) => getTranslatedEnumValue("customerStatus", cellContext.getValue()),
        meta: {
            minWidth: 100,
            collapse: true,
            options: enumToLocalizedOptions("customerStatus", CustomerStatusEnum, [CustomerStatusEnum.New]),
            filterComponent: SelectColumnFilter,
        },
    }),
];

export const tableModule = createTableModule<ShipToForSupplyLimitDto>(
    TableNames.supplyLimitBulkEdit,
    useGetShipToForSupplyLimitQuery,
    createColumns,
);
