import { type DatePickerProps, DatePicker } from "@mantine/dates";
import { isToday, parse } from "date-fns";
import type { FC } from "react";
import styled from "styled-components";

import { Color, IconSizes, ZIndex } from "src/theme";
import { DATE_FORMAT, isNotNullish } from "src/utils";
import { Icon } from "../../Icons";
import {
    createInputStyles,
    createInputWrapperStyles,
    errorMessageStyles,
    InputStylesProps,
    invalidInputStyles,
    labelStyles,
} from "../inputStyles";
import { removeUnusedPropsFromStyledInput } from "../utils";
import { DateRightSection } from "./DateRightSection";

export type { DatePickerProps as DateInputProps } from "@mantine/dates";

// datePicker is using dayJs on background for date parsing and it has a problem with DD/MM/YYYY format when free input is enabled
// therefore we have to use other library for date formatting (date-fns) which support this format
export const DateInput: FC<DatePickerProps & InputStylesProps> = ({ value, disabled, onChange, withinPortal = false, ...props }) => (
    <StyledDatePicker
        {...props}
        inputFormat="DD/MM/YYYY"
        icon={<Icon.Calendar color={Color.supportNavy200} size={IconSizes.s} />}
        zIndex={ZIndex.calendar}
        allowFreeInput
        fixOnBlur={false}
        value={value || null}
        onChange={onChange}
        disabled={disabled}
        rightSection={
            !disabled &&
            isNotNullish(value) && (
                <DateRightSection
                    onClear={() => {
                        onChange?.(null);
                    }}
                />
            )
        }
        withinPortal={withinPortal}
        dateParser={(dateString: string) => parse(dateString, DATE_FORMAT, new Date())}
        dayStyle={(date: Date) => (isToday(date) ? { backgroundColor: Color.primary100 } : null)}
        styles={{
            day: {
                "&[data-weekend]": {
                    color: `${Color.primary600}!important`,
                },
                "&[data-selected]": {
                    backgroundColor: `${Color.primary600}!important`,
                    color: `${Color.neutral50}!important`,
                },
            },
            monthPickerControlActive: {
                backgroundColor: Color.primary600,
                color: `${Color.neutral50}!important`,
            },
            yearPickerControlActive: {
                backgroundColor: Color.primary600,
                color: `${Color.neutral50}!important`,
            },
            arrow: {
                marginLeft: "0.8rem",
            },
        }}
    />
);

const StyledDatePicker = styled(DatePicker).withConfig(removeUnusedPropsFromStyledInput)<DatePickerProps & InputStylesProps>`
    ${createInputWrapperStyles}
    & input {
        ${createInputStyles}
    }

    & label {
        ${labelStyles}
    }

    & .mantine-DatePicker-invalid {
        ${invalidInputStyles}
    }

    & .mantine-DatePicker-error {
        ${errorMessageStyles}
    }
`;
