import type { FC } from "react";

import { useGetCustomerBranchesQuery } from "src/api";
import { MultiSelect, TABLE_FILTER_HEIGHT } from "src/components";
import type { ColumnFilterProps } from "src/types";
import { isString } from "src/utils";

export const BranchColumnFilter: FC<ColumnFilterProps> = ({ columnId, columnFilter, setColumnFilter }) => {
    const filterValue = columnFilter ? (columnFilter.value as string) : undefined;

    const { data: branches, isLoading } = useGetCustomerBranchesQuery();

    const options = branches?.map(({ id, name, code }) => {
        return {
            value: id,
            label: code.toString(),
            description: `${code} - ${name}`,
        };
    });

    return (
        <MultiSelect
            options={isLoading || !options ? [] : options}
            withoutBorder
            withoutErrorPlaceholder
            height={TABLE_FILTER_HEIGHT}
            fontSize="1.2rem"
            value={isString(filterValue) ? [filterValue] : filterValue}
            disableOverflow
            withinPortal
            maxDropdownHeight={300}
            clearable
            searchInputWidth="60px"
            onChange={(value) => {
                setColumnFilter(value?.length ? { id: columnId, value } : undefined); // Set undefined to remove the filter entirely
            }}
        />
    );
};
