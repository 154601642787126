import type { Row } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { FC, ReactNode } from "react";
import type { NavigateFunction } from "react-router-dom";

import { ActionIcon, Button, Confirm, Icon, TableAction, TableActionForSelection } from "src/components";
import { ConfirmContextConsumer } from "src/modal";
import { routes } from "src/routes";
import { ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import type { ExpandTableSupplySuggestionType, SupplyLimitSuggestionDto } from "src/types";

export const createActionColumn =
    (
        t: TFunction,
        navigate: NavigateFunction,
        approveSuggestions: (suggestionsIds: ReadonlyArray<number>) => void,
        refuseSuggestions: (suggestionsIds: ReadonlyArray<number>) => void,
    ) =>
    (row: Row<SupplyLimitSuggestionDto>): ReactNode => {
        const { original } = row;
        return (
            <TableActions
                supplyLimitSuggestion={original}
                t={t}
                navigate={navigate}
                approveSuggestions={approveSuggestions}
                refuseSuggestions={refuseSuggestions}
            />
        );
    };

export const createGroupedActionColumn =
    (
        t: TFunction,
        navigate: NavigateFunction,
        approveSuggestions: (suggestionsIds: ReadonlyArray<number>) => void,
        refuseSuggestions: (suggestionsIds: ReadonlyArray<number>) => void,
    ) =>
    (row: Row<ExpandTableSupplySuggestionType>): ReactNode => {
        const { original } = row;
        return (
            "shipToId" in original && (
                <TableActions
                    supplyLimitSuggestion={original}
                    t={t}
                    navigate={navigate}
                    approveSuggestions={approveSuggestions}
                    refuseSuggestions={refuseSuggestions}
                />
            )
        );
    };

type TableActionProps = Readonly<{
    supplyLimitSuggestion: SupplyLimitSuggestionDto;
    t: TFunction;
    navigate: NavigateFunction;
    approveSuggestions: (suggestionsIds: ReadonlyArray<number>) => void;
    refuseSuggestions: (suggestionsIds: ReadonlyArray<number>) => void;
}>;
const TableActions: FC<TableActionProps> = ({ supplyLimitSuggestion, approveSuggestions, refuseSuggestions, navigate, t }) => (
    <TableAction>
        {supplyLimitSuggestion.suggestedTierId ? (
            <ConfirmContextConsumer>
                {({ showConfirm }) => {
                    const confirm = async () => {
                        const confirmResult = await showConfirm({
                            title: t("dashboard.supplyLimitSuggestions.changeTier.confirm.title"),
                            content: t("dashboard.supplyLimitSuggestions.changeTier.confirm.content", {
                                fromMonthlyLimit: supplyLimitSuggestion.currentTierMonthlyLimit,
                                toMonthlyLimit: supplyLimitSuggestion.suggestedTierMonthlyLimit,
                            }),
                        });
                        if (confirmResult) {
                            approveSuggestions([supplyLimitSuggestion.id]);
                        }
                    };
                    return (
                        <ActionIcon
                            onClick={confirm}
                            color={Color.success100}
                            tooltip={t("dashboard.supplyLimitSuggestions.changeTier.tooltip")}
                        >
                            <Icon.Checkmark size={ICON_TABLE_SIZE} color={Color.success500} />
                        </ActionIcon>
                    );
                }}
            </ConfirmContextConsumer>
        ) : (
            <ActionIcon
                onClick={() =>
                    navigate(
                        routes.supplyLimitsDetail.fillPathParams({ productPackageId: supplyLimitSuggestion.productPackageId.toString() }),
                    )
                }
                color={Color.accent100}
                tooltip={t("dashboard.supplyLimitSuggestions.createSupplyLimit")}
            >
                <Icon.AddSquare size={ICON_TABLE_SIZE} color={Color.supportNavy500} />
            </ActionIcon>
        )}
        <ConfirmContextConsumer>
            {({ showConfirm }) => {
                const confirm = async () => {
                    const confirmResult = await showConfirm({
                        title: t("dashboard.supplyLimitSuggestions.removeSuggestion.confirm.title"),
                        content: t("dashboard.supplyLimitSuggestions.removeSuggestion.confirm.content"),
                    });
                    if (confirmResult) {
                        refuseSuggestions([supplyLimitSuggestion.id]);
                    }
                };
                return (
                    <ActionIcon
                        onClick={confirm}
                        color={Color.error100}
                        tooltip={t(`dashboard.supplyLimitSuggestions.removeSuggestion.tooltip`)}
                    >
                        <Icon.Delete size={ICON_TABLE_SIZE} color={Color.error500} />
                    </ActionIcon>
                );
            }}
        </ConfirmContextConsumer>
    </TableAction>
);

export const createActionsForSelection =
    (
        t: TFunction,
        bulkApprove: (suggestionIds: ReadonlyArray<number>) => void,
        bulkRefuse: (suggestionIds: ReadonlyArray<number>) => void,
    ) =>
    (_: boolean, selectedRowIds: number[]): ReactNode => {
        return (
            <TableActionForSelection>
                <Confirm
                    title={t("dashboard.supplyLimitSuggestions.approveConfirm.title")}
                    content={t("dashboard.supplyLimitSuggestions.approveConfirm.content")}
                    confirmButtonTransKey="dashboard.supplyLimitSuggestions.approveSelected"
                    onConfirm={() => {
                        bulkApprove(selectedRowIds);
                    }}
                >
                    {(open) => (
                        <Button disabled={selectedRowIds.length === 0} type="button" onClick={open}>
                            {t("dashboard.supplyLimitSuggestions.approveSelected")}
                        </Button>
                    )}
                </Confirm>
                <Confirm
                    title={t("dashboard.supplyLimitSuggestions.refuseConfirm.title")}
                    content={t("dashboard.supplyLimitSuggestions.refuseConfirm.content")}
                    confirmButtonTransKey="dashboard.supplyLimitSuggestions.refuseSelected"
                    onConfirm={() => {
                        bulkRefuse(selectedRowIds);
                    }}
                >
                    {(open) => (
                        <Button disabled={selectedRowIds.length === 0} variant="outline" type="button" onClick={open}>
                            {t("dashboard.supplyLimitSuggestions.refuseSelected")}
                        </Button>
                    )}
                </Confirm>
            </TableActionForSelection>
        );
    };
