import type { AnyRecord } from "src/types";
import { cleanObject, isString } from "src/utils";

export const convertShipToForSupplyLimitTableFilterNames = (filters: AnyRecord) => {
    const { supplyLimitDto, branch, ...clearedFilters } = filters;
    const supplyLimitDtoFixed = isString(supplyLimitDto) ? [supplyLimitDto] : supplyLimitDto;
    const branchFixed = isString(branch) ? [branch] : branch;

    return cleanObject<string | number>({
        ...clearedFilters,
        supplyLimitIds: supplyLimitDtoFixed && supplyLimitDtoFixed.length > 0 ? supplyLimitDtoFixed : undefined,
        branchIds: branchFixed && branchFixed.length > 0 ? branchFixed : undefined,
    });
};
