import { useGetGroupedWaitingEmergencyOrdersQuery } from "src/api";
import { createTableModule, TableNames } from "src/table";
import type { ExpandEmergencyOrdersType } from "src/types";
import { createGroupedColumns } from "./createGroupedColumns";

export const groupedTableModule = createTableModule<ExpandEmergencyOrdersType>(
    TableNames.emergencyOrders,
    useGetGroupedWaitingEmergencyOrdersQuery,
    createGroupedColumns,
);
