import type {
    CustomerChainWithEmergencyOrdersDto,
    CustomerChainWithSupplyLimitSuggestionsDto,
    CustomerDto,
    DataModificationLogDto,
    EmergencyOrderProductPackageListDto,
    ProductBrandWithMarginsDto,
    ProductBrandWithSpecialPricesDto,
    ProductPackageWithMarginDto,
    SpecialPriceDto,
    SupplyLimitSuggestionDto,
} from "../generated";
import type { ExpandTableType } from "../helpers";

export type ExpandTableCustomerType = ExpandTableType<CustomerDto, CustomerDto>;
export type CustomerChainWithEmergencyOrdersDtoWithId = CustomerChainWithEmergencyOrdersDto & { id: string };
export type ExpandEmergencyOrdersType = ExpandTableType<CustomerChainWithEmergencyOrdersDtoWithId, EmergencyOrderProductPackageListDto>;
export type ExpandTableDistributionMarginsType = ExpandTableType<ProductBrandWithMarginsDto, ProductPackageWithMarginDto>;
export type ExpandTableSpecialPricesType = ExpandTableType<ProductBrandWithSpecialPricesDto, SpecialPriceDto>;
export type CustomerChainWithSupplyLimitSuggestionsDtoWithId = CustomerChainWithSupplyLimitSuggestionsDto & {
    id: string;
};
export type ExpandTableSupplySuggestionType = ExpandTableType<CustomerChainWithSupplyLimitSuggestionsDtoWithId, SupplyLimitSuggestionDto>;
export type TableDataModificationLogType = Pick<DataModificationLogDto, "id" | "timestamp" | "userName" | "action" | "table"> & {
    column: Array<string>;
    oldValues?: Array<string | null>;
    newValues?: Array<string | null>;
};
export const isProductPackageWithMarginDto = (
    obj: ProductBrandWithMarginsDto | ProductPackageWithMarginDto,
): obj is ProductPackageWithMarginDto => "productPackageName" in obj && "marginSource" in obj;
export const isProductBrandWithMarginsDto = (
    obj: ProductBrandWithMarginsDto | ProductPackageWithMarginDto,
): obj is ProductBrandWithMarginsDto => "name" in obj && "margins" in obj && !isProductPackageWithMarginDto(obj);

export const isSpecialPriceDto = (obj: ProductBrandWithSpecialPricesDto | SpecialPriceDto): obj is SpecialPriceDto =>
    "productPackageName" in obj && "specialPriceSource" in obj && "specialPriceSource" in obj;
export const isProductBrandWithSpecialPricesDto = (
    obj: ProductBrandWithSpecialPricesDto | SpecialPriceDto,
): obj is ProductBrandWithSpecialPricesDto => "name" in obj && !isSpecialPriceDto(obj);

export const isCustomerChainWithEmergencyOrdersDto = (
    obj: CustomerChainWithEmergencyOrdersDtoWithId | EmergencyOrderProductPackageListDto,
): obj is CustomerChainWithEmergencyOrdersDtoWithId => "chain" in obj && !isEmergencyOrderProductPackageListDto(obj);
export const isEmergencyOrderProductPackageListDto = (
    obj: CustomerChainWithEmergencyOrdersDtoWithId | EmergencyOrderProductPackageListDto,
): obj is EmergencyOrderProductPackageListDto => {
    return "productPackageName" in obj && "customerId" in obj && "requestedOn" in obj;
};

export const isCustomerChainWithSupplyLimitSuggestionsDto = (
    obj: CustomerChainWithSupplyLimitSuggestionsDtoWithId | SupplyLimitSuggestionDto,
): obj is CustomerChainWithSupplyLimitSuggestionsDtoWithId => "chain" in obj && !isSupplyLimitSuggestionDto(obj);
export const isSupplyLimitSuggestionDto = (
    obj: CustomerChainWithSupplyLimitSuggestionsDtoWithId | SupplyLimitSuggestionDto,
): obj is SupplyLimitSuggestionDto => {
    return "productPackageName" in obj && "shipToName" in obj && "shipToId" in obj;
};
