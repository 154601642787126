import type { Row } from "@tanstack/react-table";
import type { ReactNode } from "react";
import type { TFunction } from "react-i18next";

import { Button, Confirm, Grid, TableAction, TableActionButton } from "src/components";
import type { EmergencyOrderProductPackageListDto } from "src/types";
import { EmergencyOrderProductPackageStatusEnum } from "src/types";
import { RefuseForm } from "./RefuseForm";

export const createActionColumn =
    (t: TFunction, openModal: (eoProductPackage: { id: number; name: string }) => void) =>
    ({ original }: Row<EmergencyOrderProductPackageListDto>): ReactNode =>
        (
            <TableAction>
                {original.status === EmergencyOrderProductPackageStatusEnum.Waiting && (
                    <TableActionButton type="button" onClick={() => openModal({ id: original.id, name: original.productPackageName })}>
                        {t("shipToDetail.emergencyOrders.process")}
                    </TableActionButton>
                )}
            </TableAction>
        );

export const createActionsForSelection =
    (t: TFunction, bulkApprove: (value: { emergencyOrderProductPackageIds: number[] }) => void) =>
    (_: boolean, selectedRowIds: number[]) => {
        return (
            <Grid gridAutoFlow="column" gap="1rem">
                <Confirm
                    title={t("shipToDetail.emergencyOrders.approveConfirm.title")}
                    content={t("shipToDetail.emergencyOrders.approveConfirm.content")}
                    confirmButtonTransKey="shipToDetail.emergencyOrders.approveSelected"
                    onConfirm={() => bulkApprove({ emergencyOrderProductPackageIds: selectedRowIds })}
                >
                    {(open) => (
                        <Button disabled={selectedRowIds.length === 0} type="button" onClick={open}>
                            {t("shipToDetail.emergencyOrders.approveSelected")}
                        </Button>
                    )}
                </Confirm>
                <RefuseForm selectedIds={selectedRowIds} />
            </Grid>
        );
    };

export const getApprovedAmountTextKey = (currentAmount: number, requestedAmount: number, eslMonthlyLimit: number) => {
    if (currentAmount < 1) {
        return "shipToDetail.emergencyOrders.modal.refused";
    }
    if (currentAmount === requestedAmount) {
        if (currentAmount === eslMonthlyLimit) {
            return "shipToDetail.emergencyOrders.modal.completely_within_esl";
        }
        if (currentAmount < eslMonthlyLimit) {
            return "shipToDetail.emergencyOrders.modal.completely_below_esl";
        }
        return "shipToDetail.emergencyOrders.modal.completely";
    }
    if (currentAmount < requestedAmount) {
        if (currentAmount === eslMonthlyLimit) {
            return "shipToDetail.emergencyOrders.modal.partially_within_esl";
        }
        if (currentAmount < eslMonthlyLimit) {
            return "shipToDetail.emergencyOrders.modal.partially_below_esl";
        }
        return "shipToDetail.emergencyOrders.modal.partially";
    }
    return "shipToDetail.emergencyOrders.modal.above";
};
