import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetProductBrandsWithSupplyLimitsQuery } from "src/api";
import { HighlightCell, NumberColumnFilter, TextColumnFilter } from "src/components";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { createTableModule, ParentRow, TableNames } from "src/table";
import {
    type ExpandTableProductWithSupplyLimitsType,
    type ProductBrandWithSupplyLimitsDto,
    type SupplyLimitWithPackageAndMarginDto,
    isProductBrandWithSupplyLimitsDto,
    isSupplyLimitWithPackageAndMarginDto,
} from "src/types";

const columnHelper = createColumnHelper<ProductBrandWithSupplyLimitsDto | SupplyLimitWithPackageAndMarginDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("productPackageName", {
        id: "productPackageName",
        header: t("copyTiers.supplyLimits.product") as string,
        cell: ({ row }) =>
            isProductBrandWithSupplyLimitsDto(row.original) ? <ParentRow>{row.original.name}</ParentRow> : row.original.productPackageName,
        meta: {
            width: 350,
            minWidth: 150,
            filterComponent: TextColumnFilter,
            noWrap: true,
            expandedRowColspan: 2,
        },
    }),
    columnHelper.accessor("tierNumber", {
        id: "tierNumber",
        header: t("copyTiers.supplyLimits.tier") as string,
        cell: ({ row }) =>
            isSupplyLimitWithPackageAndMarginDto(row.original) && (
                <HighlightCell highlight={!row.original.isDefault}>
                    {row.original.tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : row.original.tierNumber}
                </HighlightCell>
            ),
        meta: {
            minWidth: 120,
            filterComponent: NumberColumnFilter,
        },
    }),
    columnHelper.accessor("dailyLimit", {
        id: "dailyLimit",
        header: t("copyTiers.supplyLimits.dailyLimit") as string,
        meta: {
            minWidth: 120,
            filterComponent: NumberColumnFilter,
            align: "end",
        },
    }),
    columnHelper.accessor("monthlyLimit", {
        id: "monthlyLimit",
        header: t("copyTiers.supplyLimits.monthlyLimit") as string,
        meta: {
            minWidth: 120,
            filterComponent: NumberColumnFilter,
            align: "end",
        },
    }),
];

export const supplyLimitsTable = createTableModule<ExpandTableProductWithSupplyLimitsType>(
    TableNames.supplyLimits,
    useGetProductBrandsWithSupplyLimitsQuery,
    createColumns,
);
