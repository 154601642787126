import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetAggregationsQuery } from "src/api";
import { createTableModule, TableNames } from "src/table";
import type { AggregationDto } from "src/types";
import { dateTimeFormatter } from "src/utils";

const columnHelper = createColumnHelper<AggregationDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("sapInterfaceId", {
        id: "sapInterfaceId",
        header: t("importExport.aggregatorTool.table.sapInterfaceId") as string,
        enableColumnFilter: false,
        meta: {
            minWidth: 120,
        },
    }),
    columnHelper.accessor("salesOrganisation", {
        id: "salesOrganisation",
        header: t("importExport.aggregatorTool.table.salesOrganisation") as string,
        enableColumnFilter: false,
        meta: {
            minWidth: 120,
        },
    }),
    columnHelper.accessor("created", {
        id: "created",
        header: t("importExport.aggregatorTool.table.created") as string,
        enableColumnFilter: false,
        cell: (cellContext) => dateTimeFormatter(cellContext.getValue()),
        meta: {
            minWidth: 120,
        },
    }),
    columnHelper.accessor("dateFrom", {
        id: "dateFrom",
        header: t("importExport.aggregatorTool.table.from") as string,
        enableColumnFilter: false,
        cell: (cellContext) => dateTimeFormatter(cellContext.getValue()),
        meta: {
            minWidth: 120,
        },
    }),
    columnHelper.accessor("dateTo", {
        id: "dateTo",
        header: t("importExport.aggregatorTool.table.to") as string,
        enableColumnFilter: false,
        cell: (cellContext) => dateTimeFormatter(cellContext.getValue()),
        meta: {
            minWidth: 120,
        },
    }),
];

export const tableModule = createTableModule<AggregationDto>(TableNames.aggregatorTool, useGetAggregationsQuery, createColumns);
