import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { convertShipToForSupplyLimitTableFilterNames, useGetSupplyLimitOptionsQuery, useUpdateBulkSupplyLimitMutation } from "src/api";
import { Button, Confirm, DrawerButtonLayout, DrawerFormLayout, DrawerScrollableLayoutWithButton, LoadingOverlay } from "src/components";
import { Form, InputField } from "src/form";
import { routes } from "src/routes";
import {
    type SupplyLimitSelectProps,
    SupplyLimitHeaders,
    SupplyLimitOptionsLayout,
    SupplyLimitReadOnly,
    SupplyLimitSectionHeader,
    SupplyLimitSelect,
    UNLIMITED_TIER_NUMBER,
} from "src/supplyLimitSelect";
import { useTranslation } from "src/translations";
import type { BulkChangeSupplyLimitRequestDto } from "src/types";
import { SupplyLimitDrawerLayout } from "./components";
import type { SupplyLimitSelectParams } from "./types";
import { createQueryParams } from "./utils";

export type SupplyLimitFormProps = Readonly<{
    customersParamsForBulkEdit: SupplyLimitSelectParams;
    productPackageId: number;
    closeDrawer: () => void;
}>;

export const SupplyLimitForm: FC<SupplyLimitFormProps> = ({ customersParamsForBulkEdit, productPackageId, closeDrawer }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [updateBulkSupplyLimit] = useUpdateBulkSupplyLimitMutation();
    const { data, isLoading } = useGetSupplyLimitOptionsQuery(createQueryParams(customersParamsForBulkEdit, productPackageId));

    const initialValues = {
        shipToIds: !customersParamsForBulkEdit.isAllRowsSelected ? customersParamsForBulkEdit.selectedRowsIds : [],
        filterDto: convertShipToForSupplyLimitTableFilterNames(
            customersParamsForBulkEdit.filters.reduce((acc, { id, value }) => ({ ...acc, [id]: value }), { productPackageId }),
        ),
        supplyLimitId: data && data?.currentSupplyLimits?.length > 0 ? data.currentSupplyLimits[0].id : undefined,
    };

    const onSuccess = () => {
        navigate(routes.supplyLimitsDetail.fillPathParams({ productPackageId: productPackageId.toString() }));
    };

    return (
        <>
            <LoadingOverlay loading={isLoading} />
            {data ? (
                <Form<BulkChangeSupplyLimitRequestDto>
                    // @ts-ignore - TODO: fix typing
                    initialValues={initialValues}
                    onSubmit={updateBulkSupplyLimit}
                    onSuccess={onSuccess}
                    layoutComponent={DrawerFormLayout}
                    loadingMessage={t("supplyLimits.bulkEdit.loadingMessage")}
                    successMessage={t("supplyLimits.bulkEdit.successMessage")}
                >
                    {({ dirty, submitForm }) => (
                        <Confirm
                            title={t("supplyLimits.bulkEdit.confirm.title")}
                            contentTransKey="supplyLimits.bulkEdit.confirm.content"
                            onConfirm={submitForm}
                        >
                            {(openConfirm) => (
                                <DrawerScrollableLayoutWithButton
                                    staticSection={
                                        <DrawerButtonLayout>
                                            <Button disabled={!dirty} type="button" onClick={openConfirm}>
                                                {t("supplyLimits.bulkEdit.submit")}
                                            </Button>
                                            <Button type="button" variant="outline" onClick={closeDrawer}>
                                                {t("common.cancel")}
                                            </Button>
                                        </DrawerButtonLayout>
                                    }
                                >
                                    <SupplyLimitDrawerLayout>
                                        <SupplyLimitSectionHeader header={t("supplyLimits.bulkEdit.currentTierSection")} />
                                        <SupplyLimitHeaders />
                                        <SupplyLimitOptionsLayout>
                                            {data.currentSupplyLimits.map(({ id, tierNumber, monthlyLimit, dailyLimit, isDefault }) => (
                                                <SupplyLimitReadOnly
                                                    key={id}
                                                    tierLabel={
                                                        tierNumber === UNLIMITED_TIER_NUMBER
                                                            ? t("common.unlimited")
                                                            : tierNumber?.toString()
                                                    }
                                                    monthlyLimit={monthlyLimit}
                                                    dailyLimit={dailyLimit}
                                                    unlimited={tierNumber === UNLIMITED_TIER_NUMBER}
                                                    isDefault={isDefault}
                                                />
                                            ))}
                                        </SupplyLimitOptionsLayout>
                                        <SupplyLimitSectionHeader
                                            header={t("supplyLimits.bulkEdit.selectNewTierSection")}
                                            description={t("supplyLimits.bulkEdit.selectNewTierDescription")}
                                        />
                                        <SupplyLimitHeaders />
                                        <InputField<SupplyLimitSelectProps["value"], SupplyLimitSelectProps>
                                            name="supplyLimitId"
                                            input={SupplyLimitSelect}
                                            required
                                            inputProps={{
                                                options: data.availableSupplyLimits,
                                            }}
                                        />
                                    </SupplyLimitDrawerLayout>
                                </DrawerScrollableLayoutWithButton>
                            )}
                        </Confirm>
                    )}
                </Form>
            ) : null}
        </>
    );
};
