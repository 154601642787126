import type { FC } from "react";

import type { ColumnFilterProps } from "src/types";
import { formatDateForBE, getDateFromBEFormat } from "src/utils";
import { DateInput } from "../Form";
import { TABLE_FILTER_HEIGHT } from "./constants";

export const DateColumnFilter: FC<ColumnFilterProps> = ({ columnId, columnFilter, setColumnFilter }) => {
    const currentValue = columnFilter?.value ? getDateFromBEFormat(columnFilter.value as string) : null;

    return (
        <DateInput
            value={currentValue}
            height={TABLE_FILTER_HEIGHT}
            fontSize="1.2rem"
            withoutBorder
            withinPortal
            onChange={(value: Date | null) => {
                if (value !== null) {
                    setColumnFilter({ id: columnId, value: formatDateForBE(value) });
                } else {
                    setColumnFilter(undefined); // Set undefined to remove the filter entirely
                }
            }}
        />
    );
};
