import type { FC } from "react";
import { useState } from "react";

import { useDeleteSpecialPriceMutation } from "src/api";
import { Button, Grid, Modal } from "src/components";
import { useModal } from "src/modal";
import { useMutationNotification } from "src/notifications";
import { routes } from "src/routes";
import { useTranslation } from "src/translations";
import { createSpecialPriceActionRow, overrideSpecialPriceRowProps, SpecialPriceAttachment, SpecialPriceForm } from "../../../containers";
import { PAYER_SPECIAL_PRICES_ATTACHMENT_MODAL, PAYER_SPECIAL_PRICES_FORM_MODAL, PAYER_SPECIAL_PRICES_FORM_MODAL_WIDTH } from "./constants";
import { tableModule } from "./table";

export const PayerSpecialPrices: FC = () => {
    const { t } = useTranslation();
    const [isAttachmentModalOpened, openAttachmentModal, closeAttachmentModal] = useModal(PAYER_SPECIAL_PRICES_ATTACHMENT_MODAL);
    const [isFormModalOpened, openFormModal, closeFormModal] = useModal(PAYER_SPECIAL_PRICES_FORM_MODAL);
    const [specialPriceIdForEdit, setSpecialPriceIdForEdit] = useState<number | undefined>(undefined);
    const [deleteSpecialPrice, deleteSpecialPriceResult] = useDeleteSpecialPriceMutation();

    useMutationNotification(deleteSpecialPriceResult, "specialPrices.delete.success", "specialPrices.delete.error");

    const { customerId } = routes.customerDetail.useParams();

    const createSpecialPrice = () => {
        setSpecialPriceIdForEdit(undefined);
        openFormModal();
    };

    const editSpecialPrice = (specialPriceId: number) => {
        setSpecialPriceIdForEdit(specialPriceId);
        openFormModal();
    };

    const openSpecialPriceAttachment = (specialPriceId: number) => {
        setSpecialPriceIdForEdit(specialPriceId);
        openAttachmentModal();
    };

    return customerId ? (
        <Grid gridAutoFlow="row" gap="2rem">
            <Grid justifyContent="end">
                <Button type="button" onClick={createSpecialPrice}>
                    {t("specialPrices.createNew")}
                </Button>
            </Grid>
            <tableModule.Container
                actions={createSpecialPriceActionRow(t, editSpecialPrice, deleteSpecialPrice, openSpecialPriceAttachment)}
                isExpandable
                overrideRowProps={overrideSpecialPriceRowProps}
                additionalQueryParams={{ customerId }}
            />
            <SpecialPriceAttachment
                isOpened={isAttachmentModalOpened}
                onClose={closeAttachmentModal}
                customerId={customerId}
                specialPriceId={specialPriceIdForEdit}
            />
            <Modal
                title={specialPriceIdForEdit ? t("specialPrices.modal.updateTitle") : t("specialPrices.modal.createTitle")}
                opened={isFormModalOpened}
                onClose={closeFormModal}
                maxWidth={PAYER_SPECIAL_PRICES_FORM_MODAL_WIDTH}
            >
                <SpecialPriceForm customerId={customerId} closeModal={closeFormModal} specialPriceId={specialPriceIdForEdit} />
            </Modal>
        </Grid>
    ) : null;
};
