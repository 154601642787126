import { Select as SelectMantine, SelectProps as SelectPropsMantine } from "@mantine/core";
import { FC, forwardRef, ReactNode, useMemo } from "react";
import styled from "styled-components";

import { Color } from "src/theme";
import type { SelectOption } from "src/types";
import { isNotNullish } from "src/utils";
import {
    createInputStyles,
    createInputWrapperStyles,
    errorMessageStyles,
    InputStylesProps,
    invalidInputStyles,
    labelStyles,
} from "../inputStyles";
import { removeUnusedPropsFromStyledInput } from "../utils";
import { SelectRightSection } from "./SelectRightSection";

export type SelectProps = Omit<SelectPropsMantine, "data"> &
    Readonly<{
        options: SelectOption[];
        onChange: (newValues: string | null) => void;
        rightSection?: ReactNode;
        clearable?: boolean;
        onSearchChange?: (query: string) => void;
        loading?: boolean;
        setTouched?: (value: boolean, shouldValidate?: boolean | undefined) => void;
    }> &
    InputStylesProps;

export const Select: FC<SelectProps> = ({
    options,
    value,
    onChange,
    required,
    disabled,
    height,
    fontSize,
    rightSection,
    clearable = true,
    onSearchChange,
    loading,
    withinPortal = false,
    setTouched,
    ...props
}) => {
    const isClearable = clearable && !disabled;

    const stringOnlyOptions = useMemo(() => {
        if (loading === true) {
            return [];
        }
        return options.map((option) => ({ ...option, value: String(option.value) }));
    }, [options, loading]);
    const showClear = isNotNullish(value) && value !== "" && isClearable;

    return (
        <StyledSelect
            data={stringOnlyOptions}
            icon={options?.find((option) => option.value === value)?.icon}
            itemComponent={SelectItem}
            height={height}
            searchable
            fontSize={fontSize}
            onSearchChange={onSearchChange}
            rightSection={
                <SelectRightSection
                    loading={loading}
                    showClear={isNotNullish(value) && value !== "" && isClearable}
                    showChevron={!onSearchChange}
                    rightSection={rightSection}
                    onRemove={(event) => {
                        if (isClearable && showClear) {
                            event?.preventDefault();
                            onChange?.("");
                        }
                    }}
                />
            }
            withinPortal={withinPortal}
            required={required}
            disabled={disabled}
            value={value}
            onChange={(newValue: string) => onChange(newValue)}
            onDropdownClose={() => setTouched?.(true)}
            {...props}
            // We are using styles API from mantine because we cannot use styled components
            // in select when it is rendered in portal
            styles={{
                rightSection: { pointerEvents: "none" },
                dropdown: { fontWeight: 400 },
                item: {
                    "&[data-hovered]": {
                        color: Color.supportGraphite500,
                        backgroundColor: Color.accent100,
                    },
                    "&[data-selected]": {
                        color: Color.supportGraphite500,
                        backgroundColor: Color.accent200,
                        "&:hover": {
                            backgroundColor: Color.accent200,
                        },
                    },
                },
            }}
        />
    );
};

const SelectItem = forwardRef<HTMLDivElement, { icon?: ReactNode; value: string; label: string }>(({ label, icon, ...props }, ref) => (
    <ItemWrapper ref={ref} withIcon={Boolean(icon)} {...props}>
        {icon}
        <span>{label}</span>
    </ItemWrapper>
));

const StyledSelect = styled(SelectMantine).withConfig(removeUnusedPropsFromStyledInput)<SelectProps>`
    --left-icon-size: 5rem;
    --right-icon-size: 3rem;

    ${createInputWrapperStyles}
    & label {
        ${labelStyles}
    }

    & input {
        ${createInputStyles}
    }

    & .mantine-Select-withIcon {
        // Unfortunately library styles use !important
        padding-left: var(--left-icon-size) !important;
        padding-right: var(--right-icon-size);
    }

    & .mantine-Select-icon {
        padding-left: 0.5rem;
        width: var(--left-icon-size);
    }

    & .mantine-Select-error {
        ${errorMessageStyles}
    }

    & .mantine-Select-invalid {
        ${invalidInputStyles}
    }

    & .mantine-Select-rightSection {
        width: unset;
    }
`;

const ItemWrapper = styled.div<{ withIcon: boolean }>`
    display: grid;
    position: relative;
    grid-template-columns: ${({ withIcon }) => (withIcon ? "2rem" : "")} 1fr;
    align-items: center;
    column-gap: 1rem;
    padding: 0.8rem 1.6rem;
    font-size: 1.4rem;
`;
