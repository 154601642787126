import type { ColumnFilter } from "@tanstack/react-table";
import type { TFunction } from "i18next";

import { convertShipToForSupplyLimitTableFilterNames } from "src/api";
import { Button } from "src/components";
import type { BulkChangeSupplyLimitFiltersDto } from "src/types";
import type { SupplyLimitSelectParams } from "./types";

export const createActionsForSelection =
    (t: TFunction, openDrawer: (value: SupplyLimitSelectParams) => void) =>
    (isAllRowsSelected: boolean, selectedRowsIds: number[], filters: ColumnFilter[]) => {
        const onClick = () => {
            openDrawer({ isAllRowsSelected, selectedRowsIds, filters });
        };
        return (
            <Button disabled={!isAllRowsSelected && Object.keys(selectedRowsIds).length === 0} type="button" onClick={onClick}>
                {t("supplyLimits.bulkEdit.change")}
            </Button>
        );
    };

export const createQueryParams = (params: SupplyLimitSelectParams, productPackageId: number): BulkChangeSupplyLimitFiltersDto => {
    const filterDto = convertShipToForSupplyLimitTableFilterNames(
        params.filters.reduce((acc, { id, value }) => ({ ...acc, [id]: value }), { productPackageId }),
    );
    // @ts-ignore
    return params.isAllRowsSelected
        ? {
              ...filterDto,
          }
        : {
              ...filterDto,
              shipToIds: params.selectedRowsIds,
          };
};
