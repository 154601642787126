import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetPayerShipTosQuery } from "src/api";
import { SelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { createTableModule, TableNames } from "src/table";
import type { ShipToListDto } from "src/types";
import { CustomerStatusEnum, CustomerTypeEnum } from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue, zipFormatter } from "src/utils";

const columnHelper = createColumnHelper<ShipToListDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("name", {
        id: "name",
        header: t("payerDetail.shipTos.table.name") as string,
        cell: ({ row }) => (
            <TableLink to={routes.customerDetail.fillPathParams({ customerId: row.original.id.toString() })}>{row.original.name}</TableLink>
        ),
        meta: {
            width: 350,
            minWidth: 150,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("type", {
        id: "type",
        header: t("payerDetail.shipTos.table.type") as string,
        cell: (cellContext) => getTranslatedEnumValue("CustomerTypeEnum", cellContext.getValue()),
        meta: {
            minWidth: 100,
            options: enumToLocalizedOptions("CustomerTypeEnum", CustomerTypeEnum),
            filterComponent: SelectColumnFilter,
        },
    }),
    columnHelper.accessor("acn", {
        id: "acn",
        header: t("payerDetail.shipTos.table.acn") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("street", {
        id: "street",
        header: t("payerDetail.shipTos.table.street") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("city", {
        id: "city",
        header: t("payerDetail.shipTos.table.city") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("zip", {
        id: "zip",
        header: t("payerDetail.shipTos.table.zip") as string,
        cell: (cellContext) => zipFormatter(cellContext.getValue()),
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("tin", {
        id: "tin",
        header: t("payerDetail.shipTos.table.tin") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("status", {
        id: "status",
        header: t("payerDetail.shipTos.table.status") as string,
        cell: (cellContext) => getTranslatedEnumValue("customerStatus", cellContext.getValue()),
        meta: {
            minWidth: 100,
            options: enumToLocalizedOptions("customerStatus", CustomerStatusEnum),
            filterComponent: SelectColumnFilter,
        },
    }),
];

export const tableModule = createTableModule<ShipToListDto>(TableNames.payerShipTo, useGetPayerShipTosQuery, createColumns);
