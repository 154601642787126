import type { Row } from "@tanstack/react-table";
import { isFuture, isPast, isToday } from "date-fns";
import type { TFunction } from "i18next";

import { ActionIcon, Icon, TableAction } from "src/components";
import { ConfirmContextConsumer } from "src/modal";
import { ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import {
    type ExpandTableSpecialPricesType,
    type SpecialPriceDto,
    CustomerCredibilityEnum,
    isSpecialPriceDto,
    SpecialPriceSourceEnum,
} from "src/types";

const PRODUCT_BRAND_DEPTH_LEVEL = 0;

export const getCustomerDetailTextColor = (isInactive: boolean) => (isInactive ? Color.supportNavy300 : Color.supportNavy500);

export const getCustomerCredibilityColor = (isInactive: boolean, credibility?: CustomerCredibilityEnum) => {
    switch (credibility) {
        case CustomerCredibilityEnum.Good:
            return Color.success500;
        case CustomerCredibilityEnum.Suspicious:
            return Color.warning600;
        case CustomerCredibilityEnum.Bad:
            return Color.error500;
        default:
            return getCustomerDetailTextColor(isInactive);
    }
};

const createDeleteSpecialPriceButton = (t: TFunction, onConfirm: () => void, disabled?: boolean) => (
    <ConfirmContextConsumer>
        {({ showConfirm }) => {
            const confirm = async () => {
                const confirmResult = await showConfirm({
                    title: t("specialPrices.delete.confirm.title"),
                    content: t("specialPrices.delete.confirm.content"),
                });
                if (confirmResult) {
                    onConfirm();
                }
            };
            return (
                <ActionIcon
                    disabled={disabled}
                    onClick={confirm}
                    color={Color.error100}
                    tooltip={disabled ? t("specialPrices.actionFromPayer") : t("specialPrices.deleteTooltip")}
                >
                    <Icon.Delete size={ICON_TABLE_SIZE} color={disabled ? Color.neutral400 : Color.error500} />
                </ActionIcon>
            );
        }}
    </ConfirmContextConsumer>
);

export const isSpecialPriceInFuture = (specialPrice: SpecialPriceDto) => {
    const validFromDate = new Date(specialPrice.validFrom);
    return isFuture(validFromDate);
};

export const isSpecialPriceToday = (specialPrice: SpecialPriceDto) => {
    const validFromDate = new Date(specialPrice.validFrom);
    const validToDate = new Date(specialPrice.validTo);
    return (isPast(validFromDate) || isToday(validFromDate)) && (isToday(validToDate) || isFuture(validToDate));
};

const isDeleteButtonVisible = (specialPrice?: SpecialPriceDto) => specialPrice && isSpecialPriceInFuture(specialPrice);

const isEditButtonVisible = (specialPrice?: SpecialPriceDto) => {
    if (!specialPrice) {
        return false;
    }
    return isSpecialPriceInFuture(specialPrice) || isSpecialPriceToday(specialPrice);
};

export const createSpecialPriceActionRow =
    (
        t: TFunction,
        openProductPriceFormModal: (specialPriceId: number) => void,
        deleteProductPrice: (specialPriceId: number) => void,
        openSpecialPriceAttachment: (specialPriceId: number) => void,
    ) =>
    (row: Row<ExpandTableSpecialPricesType>): JSX.Element => {
        const specialPrice = isSpecialPriceDto(row.original) ? row.original : undefined;
        const isSpecialPriceFromPayer = specialPrice?.specialPriceSource === SpecialPriceSourceEnum.InheritedFromPayer;

        return (
            <TableAction>
                {specialPrice && (
                    <>
                        <ActionIcon
                            disabled={isSpecialPriceFromPayer}
                            onClick={() => openSpecialPriceAttachment(specialPrice.id)}
                            color={Color.accent100}
                            tooltip={t("specialPrices.attachmentTooltip")}
                        >
                            <Icon.Attach
                                size={ICON_TABLE_SIZE}
                                color={isSpecialPriceFromPayer ? Color.neutral400 : Color.primary600}
                                filled
                            />
                        </ActionIcon>
                        {isEditButtonVisible(specialPrice) && (
                            <ActionIcon
                                disabled={isSpecialPriceFromPayer}
                                tooltip={isSpecialPriceFromPayer ? t("specialPrices.actionFromPayer") : t("specialPrices.editTooltip")}
                                onClick={() => openProductPriceFormModal(specialPrice.id)}
                                color={Color.accent100}
                            >
                                <Icon.Edit
                                    size={ICON_TABLE_SIZE}
                                    color={isSpecialPriceFromPayer ? Color.neutral400 : Color.primary600}
                                    filled
                                />
                            </ActionIcon>
                        )}
                        {isDeleteButtonVisible(specialPrice) &&
                            createDeleteSpecialPriceButton(t, () => deleteProductPrice(specialPrice.id), isSpecialPriceFromPayer)}
                    </>
                )}
            </TableAction>
        );
    };

export const overrideSpecialPriceRowProps = (row: Row<ExpandTableSpecialPricesType>): Record<string, unknown> => {
    if (row.depth === PRODUCT_BRAND_DEPTH_LEVEL) {
        return { $backgroundColor: Color.neutral50 };
    }
    // @ts-ignore
    if (row.original.active === false) {
        return { $backgroundColor: Color.supportGraphite50 };
    }
    return { $backgroundColor: Color.white };
};
