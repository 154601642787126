import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "i18next";

import { HighlightCell, TableLink, Tooltip } from "src/components";
import { routes } from "src/routes";
import { ParentRow } from "src/table";
import { Color } from "src/theme";
import {
    type CustomerChainWithSupplyLimitSuggestionsDtoWithId,
    type SupplyLimitSuggestionDto,
    isCustomerChainWithSupplyLimitSuggestionsDto,
    isSupplyLimitSuggestionDto,
} from "src/types";

const columnHelper = createColumnHelper<CustomerChainWithSupplyLimitSuggestionsDtoWithId | SupplyLimitSuggestionDto>();

export const createGroupedColumns = (t: TFunction) => [
    columnHelper.accessor("chain", {
        id: "chain",
        header: t("dashboard.supplyLimitSuggestions.table.name") as string,
        cell: ({ row }) => {
            return row.getCanExpand() && isCustomerChainWithSupplyLimitSuggestionsDto(row.original) ? (
                <ParentRow>{row.original.chain}</ParentRow>
            ) : (
                isSupplyLimitSuggestionDto(row.original) && (
                    <TableLink to={routes.customerDetail.fillPathParams({ customerId: row.original.shipToId.toString() })}>
                        {row.original.shipToName}
                    </TableLink>
                )
            );
        },
        enableColumnFilter: false,
        meta: {
            width: 350,
            minWidth: 150,

            expandedRowColspan: 3,
        },
    }),
    columnHelper.accessor("shipToAcn", {
        id: "shipToAcn",
        header: t("dashboard.supplyLimitSuggestions.table.acn"),
        enableColumnFilter: false,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("productPackageName", {
        id: "productPackageName",
        header: t("dashboard.supplyLimitSuggestions.table.product"),
        enableColumnFilter: false,
        cell: ({ row }) =>
            isSupplyLimitSuggestionDto(row.original) && (
                <TableLink to={routes.supplyLimitsDetail.fillPathParams({ productPackageId: row.original.productPackageId.toString() })}>
                    {row.original.productPackageName}
                </TableLink>
            ),
        meta: {
            width: 250,
            minWidth: 150,
        },
    }),
    columnHelper.accessor("currentTierNumber", {
        id: "currentTierNumber",
        header: t("dashboard.supplyLimitSuggestions.table.currentTier"),
        enableColumnFilter: false,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("currentTierMonthlyLimit", {
        id: "currentTierMonthlyLimit",
        header: t("dashboard.supplyLimitSuggestions.table.currentMonthlyLimit"),
        enableColumnFilter: false,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("averageRequestedAmount", {
        id: "averageRequestedAmount",
        header: t("dashboard.supplyLimitSuggestions.table.averageRequestedAmount"),
        enableColumnFilter: false,
        meta: {
            minWidth: 150,
        },
    }),
    columnHelper.accessor("approvedEmergencyOrdersCount", {
        id: "approvedEmergencyOrdersCount",
        header: t("dashboard.supplyLimitSuggestions.table.approvedCount"),
        enableColumnFilter: false,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("suggestedTierNumber", {
        id: "suggestedTierNumber",
        header: t("dashboard.supplyLimitSuggestions.table.suggestedTierNumber"),
        enableColumnFilter: false,
        cell: ({ row }) =>
            isSupplyLimitSuggestionDto(row.original) && <HighlightCell highlight>{row.original.suggestedTierNumber}</HighlightCell>,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("suggestedTierMonthlyLimit", {
        id: "suggestedTierMonthlyLimit",
        header: t("dashboard.supplyLimitSuggestions.table.suggestedTierMonthlyLimit"),
        enableColumnFilter: false,
        cell: ({ row }) =>
            isSupplyLimitSuggestionDto(row.original) && <HighlightCell highlight>{row.original.suggestedTierMonthlyLimit}</HighlightCell>,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("suggestedTierMonthlyLimit", {
        id: "idealMonthlyLimit",
        header: t("dashboard.supplyLimitSuggestions.table.idealMonthlyLimit"),
        enableColumnFilter: false,
        cell: ({ row }) =>
            isSupplyLimitSuggestionDto(row.original) && (
                <Tooltip
                    withinPortal={false}
                    color={Color.supportGraphite500}
                    backgroundColor={Color.accent50}
                    withArrow
                    label={t("dashboard.supplyLimitSuggestions.table.idealMonthlyLimitTooltip")}
                >
                    <span>{row.original.currentTierMonthlyLimit + row.original.averageRequestedAmount} </span>
                </Tooltip>
            ),
    }),
];
