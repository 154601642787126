import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "i18next";

import { sortedCustomerCredibilityEnumToLocalizedOptions } from "src/app/customers/containers/ChangeCredibilityForm";
import { EmergencyOrderProcessedCell, HighlightCell, SelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { ParentRow } from "src/table";
import {
    type CustomerChainWithEmergencyOrdersDtoWithId,
    type EmergencyOrderProductPackageListDto,
    isCustomerChainWithEmergencyOrdersDto,
    isEmergencyOrderProductPackageListDto,
} from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter } from "src/utils";

const columnHelper = createColumnHelper<CustomerChainWithEmergencyOrdersDtoWithId | EmergencyOrderProductPackageListDto>();

export const createGroupedColumns = (t: TFunction) => [
    columnHelper.accessor("chain", {
        id: "chain",
        header: t("dashboard.emergencyOrders.table.requestedOn") as string,
        enableColumnFilter: false,
        cell: ({ row }) => {
            return row.getCanExpand() && isCustomerChainWithEmergencyOrdersDto(row.original) ? (
                <ParentRow>{row.original.chain}</ParentRow>
            ) : (
                isEmergencyOrderProductPackageListDto(row.original) && dateTimeFormatter(row.original.requestedOn, DATE_TIME_FORMAT)
            );
        },
        meta: {
            collapse: true,
            minWidth: 140,

            expandedRowColspan: 3,
        },
    }),
    columnHelper.accessor("customerName", {
        id: "customerName",
        header: t("dashboard.emergencyOrders.table.requestedFor") as string,
        cell: ({ row }) =>
            !row.getCanExpand() &&
            isEmergencyOrderProductPackageListDto(row.original) && (
                <TableLink to={routes.customerDetail.fillPathParams({ customerId: row.original.customerId.toString() })}>
                    {row.original.customerName}
                </TableLink>
            ),
        meta: {
            width: 250,
            minWidth: 120,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("customerAcn", {
        id: "customerAcn",
        header: t("dashboard.emergencyOrders.table.acn"),
        meta: {
            width: 150,
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("productPackageName", {
        header: t("dashboard.emergencyOrders.table.product"),
        meta: {
            width: 150,
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("tierNumber", {
        id: "tierNumber",
        header: t("dashboard.emergencyOrders.table.tierNumber"),
        cell: ({ row }) =>
            !row.getCanExpand() &&
            isEmergencyOrderProductPackageListDto(row.original) && (
                <HighlightCell highlight={!row.original.isDefaultTier}>
                    {row.original.tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : row.original.tierNumber}
                </HighlightCell>
            ),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("dailyLimit", {
        id: "dailyLimit",
        header: t("dashboard.emergencyOrders.table.dailyLimit"),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("monthlyLimit", {
        id: "monthlyLimit",
        header: t("dashboard.emergencyOrders.table.monthlyLimit"),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("monthlyVolumeAdjustment", {
        id: "monthlyVolumeAdjustment",
        header: t("dashboard.emergencyOrders.table.monthlyVolumeAdjustment"),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("requestedAmount", {
        id: "requestedAmount",
        header: t("dashboard.emergencyOrders.table.requestedAmount"),
        enableColumnFilter: false,
        meta: {
            collapse: true,
            align: "center",
        },
    }),
    columnHelper.accessor("approvedThisMonth", {
        id: "approvedThisMonth",
        header: t("dashboard.emergencyOrders.table.processed.header"),
        enableColumnFilter: false,
        cell: ({ row }) =>
            !row.getCanExpand() &&
            isEmergencyOrderProductPackageListDto(row.original) && <EmergencyOrderProcessedCell row={row.original} />,
        meta: {
            align: "center",
        },
    }),
    columnHelper.accessor("orderReason", {
        id: "orderReason",
        header: t("dashboard.emergencyOrders.table.description"),
        enableColumnFilter: false,
        meta: {
            minWidth: 120,
        },
    }),
    columnHelper.accessor("customerCredibility", {
        id: "customerCredibility",
        header: t("dashboard.emergencyOrders.table.credibility"),
        meta: {
            minWidth: 120,
            width: 120,
            options: sortedCustomerCredibilityEnumToLocalizedOptions(),
            filterComponent: SelectColumnFilter,
        },
    }),
];
