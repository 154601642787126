import type { FC } from "react";

import { useUpdateDistributionMarginForPayerMutation } from "src/api";
import { Button, ModalButtonLayout, NumberInput, NumberInputProps, Text } from "src/components";
import { Form, GlobalFormError, InputField, percentageInputProps } from "src/form";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { UpdateMarginsForPayerDtoFixed } from "src/types";
import { hasPropOfType, isNumber, useAppDispatch } from "src/utils";
import { tableModule } from "./table";
import type { ModalAttributes } from "./types";

type ShipToSupplyLimitFormProps = {
    modalAttributes: ModalAttributes | undefined;
    closeModal: () => void;
};

export const DistributionMarginsForm: FC<ShipToSupplyLimitFormProps> = ({ modalAttributes, closeModal }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [updateDistributionMargin] = useUpdateDistributionMarginForPayerMutation();

    const initialValues = {
        margin: modalAttributes?.marginValue,
    };

    const onSubmit = (values: UpdateMarginsForPayerDtoFixed) => {
        if (modalAttributes?.filterFromTable && hasPropOfType(modalAttributes.filterFromTable, "customerId", isNumber)) {
            return updateDistributionMargin({
                margin: values.margin,
                productPackageIds: modalAttributes.productPackagesWithMarginIds,
                filterDto: modalAttributes.filterFromTable,
            })
                .unwrap()
                .then(() => dispatch(tableModule.resetTableSelectionAction));
        }
        throw new GlobalFormError("No customerId found in modalAttributes, or it has wrong type. Please contact administrator.");
    };

    return (
        <Form<UpdateMarginsForPayerDtoFixed>
            initialValues={initialValues}
            onSubmit={onSubmit}
            onSuccess={closeModal}
            unwrapOnSubmit={false}
            loadingMessage={t("payerDetail.distributionMargins.loadingMessage")}
            successMessage={t("payerDetail.distributionMargins.successMessage")}
        >
            {({ dirty }) => (
                <>
                    <Text color={Color.supportNavy500}>{t("payerDetail.distributionMargins.info")}</Text>
                    <InputField<NumberInputProps["value"], NumberInputProps>
                        name="margin"
                        input={NumberInput}
                        type="number"
                        inputProps={{
                            label: t("payerDetail.distributionMargins.margin.label"),
                            placeholder: t("payerDetail.distributionMargins.margin.placeholder"),
                            maxWidth: "180px",
                            ...percentageInputProps,
                        }}
                    />
                    <ModalButtonLayout>
                        <Button type="submit" disabled={!dirty}>
                            {t("common.confirm")}
                        </Button>
                        <Button type="button" onClick={closeModal} variant="outline">
                            {t("common.cancel")}
                        </Button>
                    </ModalButtonLayout>
                </>
            )}
        </Form>
    );
};
