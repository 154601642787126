import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetProductBrandsQuery } from "src/api";
import {
    ArchiveIcon,
    createBooleanSelectOption,
    CurrentPriceCell,
    Icon,
    NumberColumnFilter,
    SelectColumnFilter,
    TextColumnFilter,
} from "src/components";
import { createTableModule, ICON_TABLE_SIZE, ParentRow, TableNames } from "src/table";
import { Color } from "src/theme";
import {
    type ExpandTableProductType,
    type ProductBrandDto,
    type ProductPackageDto,
    isProductBrandDto,
    isProductPackageDto,
} from "src/types";
import { currencyFormatter, dateTimeFormatter, percentageFormatter } from "src/utils";

const columnHelper = createColumnHelper<ProductBrandDto | ProductPackageDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("name", {
        id: "name",
        header: t("products.table.name") as string,
        cell: ({ row }) => (isProductBrandDto(row.original) ? <ParentRow>{row.original.name}</ParentRow> : row.original.name),
        meta: {
            width: 350,
            minWidth: 150,
            filterComponent: TextColumnFilter,
            noWrap: true,

            expandedRowColspan: 3,
        },
    }),
    columnHelper.accessor("sapCode", {
        id: "sapCode",
        header: t("products.table.sapCode") as string,
        meta: {
            minWidth: 120,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("agentCode", {
        id: "agentCode",
        header: t("products.table.agentCode") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("suklCode", {
        id: "suklCode",
        header: t("products.table.suklCode") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("margin", {
        id: "margin",
        header: t("products.table.margin") as string,
        cell: (cellContext) => percentageFormatter(cellContext.getValue() as number),
        meta: {
            minWidth: 70,
            align: "flex-end",
            filterComponent: NumberColumnFilter,
            noWrap: true,
            collapse: true,
        },
    }),
    columnHelper.accessor("active", {
        id: "active",
        header: t("products.table.active") as string,
        cell: (cellContext) => {
            const isParentRow = cellContext.row.depth === 0;
            if (isParentRow) {
                return cellContext.getValue() === false ? (
                    <Icon.Close
                        tooltipBackgroundColor={Color.error500}
                        tooltip={t("common.inactive")}
                        size={ICON_TABLE_SIZE}
                        color={Color.error500}
                    />
                ) : null;
            }
            return cellContext.getValue() && !isParentRow ? (
                <Icon.Checkmark
                    tooltipBackgroundColor={Color.success500}
                    tooltip={t("common.active")}
                    size={ICON_TABLE_SIZE}
                    color={Color.success500}
                />
            ) : (
                <Icon.Close
                    tooltipBackgroundColor={Color.error500}
                    tooltip={t("common.inactive")}
                    size={ICON_TABLE_SIZE}
                    color={Color.error500}
                />
            );
        },
        meta: {
            align: "flex-end",
            collapse: true,
            minWidth: 100,
            filterComponent: SelectColumnFilter,
            options: createBooleanSelectOption(t("common.active"), t("common.inactive")),
        },
    }),
    columnHelper.accessor("archived", {
        id: "archived",
        header: t("products.table.archived") as string,
        cell: (cellContext) => (cellContext.getValue() ? <ArchiveIcon tooltip={t("common.archived")} color={Color.neutral400} /> : null),
        meta: {
            align: "flex-end",
            collapse: true,
            minWidth: 120,
            filterComponent: SelectColumnFilter,
            options: createBooleanSelectOption(t("common.archived"), t("common.unarchived")),
        },
    }),
    columnHelper.accessor("currentPrice", {
        id: "currentPrice",
        header: t("products.table.price") as string,
        enableColumnFilter: false,
        cell: ({ row }) =>
            isProductPackageDto(row.original) && row.original.currentPrice ? (
                <CurrentPriceCell
                    disabled={!row.original.active}
                    date={`${t("products.table.validToPrice")} ${dateTimeFormatter(row.original.currentPrice.validTo)}`}
                    price={currencyFormatter(row.original.currentPrice?.price)}
                />
            ) : null,
        meta: {
            align: "flex-end",
            collapse: true,
            noWrap: true,
            minWidth: 100,
        },
    }),
];

export const tableModule = createTableModule<ExpandTableProductType>(TableNames.productList, useGetProductBrandsQuery, createColumns);
