import type { RefObject } from "react";
import styled from "styled-components";

/**
 * Table component
 * Ready to use with react-table
 */
export const Table = styled.table<{ ref: RefObject<HTMLTableElement> }>`
    border-collapse: collapse;
    width: 100%;

    th,
    td {

        /* Each cell should grow equally */
        /* But "collapsed" cells should be as small as possible */

        &.collapse {
            width: 0.0000000001%;
        }
    }

    tr:last-child td {
        border-bottom: none;
    }

    tr {
        td:first-child {
            border-left: none;
            border-bottom: none;
        }

        td:last-child {
            border-right: none;
            border-bottom: none;
        }
    }

    tr:first-child {
        th:first-child {
            border-top-left-radius: ${({ theme }) => theme.radius.default};
        }

        th:last-child {
            border-top-right-radius: ${({ theme }) => theme.radius.default};
        }
    }

    tr:last-child {
        td:first-child {
            border-bottom-left-radius: ${({ theme }) => theme.radius.default};
        }

        td:last-child {
            border-bottom-right-radius: ${({ theme }) => theme.radius.default};
        }
    }

    tr:not(:last-child) {
        td:first-child:before, td:last-child:before {
            z-index: 1;
            content: "";
            position: absolute;
            width: 80%;
            height: 0.5px;
            background-color: ${({ theme }) => theme.color.supportGraphite50};
            bottom: 0;
        }

        td:first-child:before {
            right: 0;
        }

        td:last-child:before {
            left: 0;
        }
    }
}
`;
