import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetProductBrandsWithSupplyLimitsQuery } from "src/api";
import { HighlightCell, NumberColumnFilter, SelectColumnFilter, TextColumnFilter, Tooltip } from "src/components";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { createTableModule, ParentRow, TableNames } from "src/table";
import { Color } from "src/theme";
import {
    type ExpandTableProductWithSupplyLimitsType,
    type ProductBrandWithSupplyLimitsDto,
    type SupplyLimitWithPackageAndMarginDto,
    isProductBrandWithSupplyLimitsDto,
    isSupplyLimitWithPackageAndMarginDto,
    MarginSourceEnum,
} from "src/types";
import { enumToLocalizedOptions, getTranslatedEnumValue, percentageFormatter } from "src/utils";
import { SupplyLimitAdjustmentCell } from "../../../components";

const columnHelper = createColumnHelper<ProductBrandWithSupplyLimitsDto | SupplyLimitWithPackageAndMarginDto>();
const createColumns = (t: TFunction) => {
    return [
        columnHelper.accessor("productPackageName", {
            id: "productPackageName",
            header: t("shipToDetail.supplyLimit.table.name") as string,
            cell: ({ row }) =>
                isProductBrandWithSupplyLimitsDto(row.original) ? (
                    <ParentRow>{row.original.name}</ParentRow>
                ) : (
                    row.original.productPackageName
                ),
            meta: {
                width: 350,
                minWidth: 120,
                filterComponent: TextColumnFilter,
                noWrap: true,
            },
        }),
        columnHelper.accessor("tierNumber", {
            id: "tierNumber",
            header: t("shipToDetail.supplyLimit.table.tierNumber") as string,
            cell: ({ row }) =>
                isSupplyLimitWithPackageAndMarginDto(row.original) && (
                    <HighlightCell highlight={!row.original.isDefault}>
                        {row.original.tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : row.original.tierNumber}
                    </HighlightCell>
                ),
            meta: {
                minWidth: 100,
                collapse: true,
                filterComponent: NumberColumnFilter,
            },
        }),
        columnHelper.accessor("dailyLimit", {
            id: "dailyLimit",
            header: t("shipToDetail.supplyLimit.table.dailyLimit") as string,
            cell: ({ row }) =>
                isSupplyLimitWithPackageAndMarginDto(row.original) && (
                    <SupplyLimitAdjustmentCell t={t} tierLimit={row.original.dailyLimit} adjustment={row.original.dailyVolumeAdjustment} />
                ),
            meta: {
                minWidth: 100,
                filterComponent: NumberColumnFilter,
                align: "end",
            },
        }),
        columnHelper.accessor("monthlyLimit", {
            id: "monthlyLimit",
            header: t("shipToDetail.supplyLimit.table.monthlyLimit") as string,
            cell: ({ row }) =>
                isSupplyLimitWithPackageAndMarginDto(row.original) && (
                    <SupplyLimitAdjustmentCell
                        t={t}
                        tierLimit={row.original.monthlyLimit}
                        adjustment={row.original.monthlyVolumeAdjustment}
                    />
                ),
            meta: {
                minWidth: 100,
                filterComponent: NumberColumnFilter,
                align: "end",
            },
        }),
        columnHelper.accessor("margin", {
            id: "margin",
            header: t("shipToDetail.supplyLimit.table.margin") as string,
            cell: ({ row }) =>
                isSupplyLimitWithPackageAndMarginDto(row.original) && (
                    <Tooltip
                        withinPortal={false}
                        color={Color.supportGraphite500}
                        backgroundColor={Color.accent50}
                        withArrow
                        label={getTranslatedEnumValue("marginSource", row.original.marginSource)}
                    >
                        <HighlightCell highlight={row.original.marginSource !== MarginSourceEnum.ProductPackage}>
                            {percentageFormatter(row.original.margin)}
                        </HighlightCell>
                    </Tooltip>
                ),
            meta: {
                minWidth: 100,
                options: enumToLocalizedOptions("marginSource", MarginSourceEnum),
                filterComponent: SelectColumnFilter,
            },
        }),
    ];
};

export const tableModule = createTableModule<ExpandTableProductWithSupplyLimitsType>(
    TableNames.ShipToSupplyLimits,
    useGetProductBrandsWithSupplyLimitsQuery,
    createColumns,
);
