import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetCustomersToBlockQuery } from "src/api";
import { createBooleanSelectOption, SelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { createTableModule, TableNames } from "src/table";
import { Color } from "src/theme";
import type { CustomerDebtListDto } from "src/types";
import { currencyFormatter } from "src/utils";
import { IsHospitalCell } from "../../../components";

const columnHelper = createColumnHelper<CustomerDebtListDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("name", {
        id: "name",
        header: t("customers.table.payerName") as string,
        cell: ({ row }) => (
            <TableLink
                color={Color.primary600}
                weight={700}
                to={routes.customerDetail.fillPathParams({
                    customerId: row.original.id.toString(),
                })}
            >
                {row.original.name}
            </TableLink>
        ),
        meta: {
            width: 350,
            minWidth: 120,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("acn", {
        id: "acn",
        header: t("customers.table.acn") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("tin", {
        id: "tin",
        header: t("customers.table.tin") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("branches", {
        id: "branches",
        header: t("customers.table.isHospital") as string,
        cell: ({ row }) => <IsHospitalCell t={t} branches={row.original.branches} />,
        meta: {
            minWidth: 75,
            options: createBooleanSelectOption(t("common.yes"), t("common.no")),
            filterComponent: SelectColumnFilter,
        },
    }),
    columnHelper.accessor("totalGross", {
        id: "totalGross",
        header: t("customers.table.totalGross") as string,
        cell: (cellContext) => currencyFormatter(cellContext.getValue()),
        enableColumnFilter: false,
        meta: {
            align: "end",
            minWidth: 100,
            noWrap: true,
        },
    }),
    columnHelper.accessor("day0814", {
        id: "day0814",
        header: t("customers.table.day0814") as string,
        cell: (cellContext) => currencyFormatter(cellContext.getValue()),
        enableColumnFilter: false,
        meta: {
            align: "end",
            minWidth: 80,
            collapse: true,
            noWrap: true,
        },
    }),
    columnHelper.accessor("day1521", {
        id: "day1521",
        header: t("customers.table.day1521") as string,
        cell: (cellContext) => currencyFormatter(cellContext.getValue()),
        enableColumnFilter: false,
        meta: {
            align: "end",
            minWidth: 80,
            collapse: true,
            noWrap: true,
        },
    }),
    columnHelper.accessor("day2228", {
        id: "day2228",
        header: t("customers.table.day2228") as string,
        cell: (cellContext) => currencyFormatter(cellContext.getValue()),
        enableColumnFilter: false,
        meta: {
            align: "end",
            minWidth: 80,
            collapse: true,
            noWrap: true,
        },
    }),
    columnHelper.accessor("day2960", {
        id: "day2960",
        header: t("customers.table.day2960") as string,
        cell: (cellContext) => currencyFormatter(cellContext.getValue()),
        enableColumnFilter: false,
        meta: {
            align: "end",
            minWidth: 80,
            collapse: true,
            noWrap: true,
        },
    }),
    columnHelper.accessor("day6190", {
        id: "day6190",
        header: t("customers.table.day6190") as string,
        cell: (cellContext) => currencyFormatter(cellContext.getValue()),
        enableColumnFilter: false,
        meta: {
            align: "end",
            minWidth: 80,
            collapse: true,
            noWrap: true,
        },
    }),
    columnHelper.accessor("day90", {
        id: "day90",
        header: t("customers.table.day90") as string,
        cell: (cellContext) => currencyFormatter(cellContext.getValue()),
        enableColumnFilter: false,
        meta: {
            align: "end",
            minWidth: 80,
            collapse: true,
            noWrap: true,
        },
    }),
];

export const tableModule = createTableModule<CustomerDebtListDto>(TableNames.customersBlocked, useGetCustomersToBlockQuery, createColumns);
