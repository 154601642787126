import { IconSizes } from "src/theme";

export const NAME = "tableModule" as string;

export const ACTION_COLUMN_ID = "actions" as const;
export const SELECT_COLUMN_ID = "select" as const;
export const EXPAND_COLUMN_ID = "expander" as const;
export const FILTERS_SEARCH_PARAM_BASE = "filters" as const;
export const ICON_TABLE_SIZE = IconSizes.s;
export const LOADING_DELAY = 200;
export const PARENT_ROW_PREFIX = "parent_";
