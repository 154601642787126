import type { FC } from "react";

import type { ColumnFilterProps } from "src/types";
import { Select } from "../Form";
import { TABLE_FILTER_HEIGHT } from "./constants";

export const createBooleanSelectOption = (trueLabel: string, falseLabel: string) => [
    { value: "true", label: trueLabel },
    { value: "false", label: falseLabel },
];

export const SelectColumnFilter: FC<ColumnFilterProps> = ({ columnId, columnFilter, setColumnFilter, options }) => {
    const filterValue = columnFilter?.value as string | undefined;

    return (
        <Select
            options={options ?? []}
            withoutBorder
            withoutErrorPlaceholder
            height={TABLE_FILTER_HEIGHT}
            fontSize="1.2rem"
            value={filterValue || ""}
            withinPortal
            onChange={(value) => {
                setColumnFilter({ id: columnId, value: value || "" });
            }}
        />
    );
};
