import { useDebouncedValue, useHotkeys } from "@mantine/hooks";
import {
    type Cell,
    type ColumnFilter,
    type ExpandedState,
    type Row,
    type RowSelectionState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    useReactTable,
} from "@tanstack/react-table";
import * as equal from "fast-deep-equal/es6/react";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
    Button,
    Grid,
    LoadingOverlay,
    Pagination,
    Table,
    TableBorder,
    TableLayout,
    TableScrollDecoration,
    TableSelectionTop,
    TableWithPaginationLayout,
    TBody,
    TCell,
    Text,
    THead,
    TRow,
} from "src/components";
import { createSearchParamsAsString } from "src/routes";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { TableRecordType } from "src/types";
import { cleanObject, useAppDispatch, useAppParamSelector, useOnUpdate } from "src/utils";
import { saveFilters } from "../actions";
import { EmptyRow } from "../components";
import { ACTION_COLUMN_ID, PARENT_ROW_PREFIX } from "../constants";
import { getResetSelectionTrigger } from "../selectors";
import type { DefaultTableConfig, TableContainerProps } from "../types";
import { createFiltersSearchParam } from "../utils";
import {
    addSpecialColumns,
    collectCellProps,
    convertFilterParamsToFilters,
    convertFiltersToFilterParams,
    useTableDecoration,
} from "./utils";

export const TableContainer = <RecordType extends TableRecordType>({
    tableId,
    tableName,
    createColumns,
    data,
    defaultTableConfig,
    pageCount: controlledPageCount,
    fetchWithNewParams,
    overrideRowProps,
    initialFilterValuesFromUrl,
    isLoading,
    isSelectable,
    canSelectAll,
    isExpandable,
    totalElements,
    actions,
    actionsForSelection,
    setPersistedPageSize,
    maxWidth,
    emptyRow,
}: TableContainerProps<RecordType>): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>(convertFilterParamsToFilters(initialFilterValuesFromUrl));
    const resetSelectionTrigger = useAppParamSelector(getResetSelectionTrigger, tableId);

    const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
    const [areFiltersDirty, setAreFiltersDirty] = useState(false);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [expanded, setExpanded] = useState<ExpandedState>({});

    const columns = useMemo(() => createColumns(t, tableName), [t, createColumns, tableName]);
    const allColumns = useMemo(
        () => addSpecialColumns(columns, !!isSelectable, isAllRowsSelected, setIsAllRowsSelected, setRowSelection, !!isExpandable),
        [columns, isAllRowsSelected, isSelectable, isExpandable],
    );

    const filterSearchParam = useMemo(() => createFiltersSearchParam(tableName), [tableName]);

    const table = useReactTable({
        data,
        columns: allColumns,
        getSubRows: (row) => row.subRows,
        getRowId: (row) => (row.subRows?.length ? `${PARENT_ROW_PREFIX}${row.id.toString()}` : row.id.toString()),
        onRowSelectionChange: setRowSelection,
        onExpandedChange: setExpanded,
        initialState: createInitialState(defaultTableConfig),
        state: {
            columnFilters,
            rowSelection,
            expanded,
        },
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        manualPagination: true,
        manualFiltering: true,
        filterFromLeafRows: true,
        maxLeafRowFilterDepth: 1,
    });

    const { rows, flatRows } = table.getRowModel();

    const changePageSize = (value: number) => {
        setPersistedPageSize(value);
        table.setPageSize(value);
    };

    const selectAllPages = () => {
        setIsAllRowsSelected(true);
    };

    const deselectAllPages = () => {
        setRowSelection({});
        setIsAllRowsSelected(false);
    };

    const { tableRef, wrapperRef, isLeftDecorationVisible, isRightDecorationVisible } = useTableDecoration();

    const headerGroups = table.getHeaderGroups();

    const {
        pagination: { pageSize, pageIndex },
    } = table.getState();

    const [debouncedFilters] = useDebouncedValue<ColumnFilter[]>(columnFilters, 500);

    const resetFiltersToInitialValue = () => {
        setColumnFilters(convertFilterParamsToFilters(defaultTableConfig.initialFilters));
    };

    useHotkeys([["mod+Backspace", resetFiltersToInitialValue]]);

    // Simulate all rows (also child rows) selection across pages
    useEffect(() => {
        if (isAllRowsSelected) {
            setRowSelection((prev) => flatRows.reduce((all, r) => ({ ...all, [r.id]: true }), prev));
        }
    }, [flatRows, isAllRowsSelected]);

    // Save filters on unmount
    useEffect(
        () => () => {
            dispatch(saveFilters({ tableName, filters: convertFiltersToFilterParams(debouncedFilters) }));
        },
        [dispatch, tableName, debouncedFilters],
    );

    // Fetch new records witch new search params
    useEffect(() => {
        const filterParams = convertFiltersToFilterParams(debouncedFilters);

        fetchWithNewParams({ pageIndex, pageSize, filters: filterParams });
    }, [fetchWithNewParams, pageIndex, pageSize, debouncedFilters]);

    // Expand rows when filter is active, check if pagination is set correctly after data refetch
    useEffect(() => {
        const filterParams = convertFiltersToFilterParams(debouncedFilters);
        if (debouncedFilters.length !== 0 && !equal(filterParams, defaultTableConfig.initialFilters)) {
            const expandedRows = data.reduce((all, curr) => ({ ...all, [`${PARENT_ROW_PREFIX}${curr.id}`]: true }), {});

            table.setExpanded(expandedRows);
        }

        if (!!controlledPageCount && pageIndex + 1 > controlledPageCount) {
            table.setPageIndex(controlledPageCount - 1);
        }

        // eslint-disable-next-line
    }, [data]);

    // Close expanded rows when you clear filters, you cannot bind this action to data change,
    // because you want to keep rows expanded when you create new entity in table and refetch data
    useEffect(() => {
        const filterParams = convertFiltersToFilterParams(debouncedFilters);
        const isDirty = !equal(filterParams, defaultTableConfig.initialFilters);

        const currentParams = Object.fromEntries([...searchParams]);
        const cleanParams = cleanObject({ ...currentParams, [filterSearchParam]: createSearchParamsAsString(filterParams) });
        setSearchParams(cleanParams, { replace: true });

        if (!debouncedFilters.length || !isDirty) {
            table.setExpanded({});
        }

        setAreFiltersDirty(isDirty);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilters, filterSearchParam]);

    // Reset selection when resetSelectionTrigger is updated
    useOnUpdate(() => {
        deselectAllPages();
    }, [resetSelectionTrigger]);

    const selectedIdsWithoutPossibleParentIds = Object.keys(rowSelection)
        .filter((id) => !id.startsWith(PARENT_ROW_PREFIX))
        .map((id) => Number(id))
        .filter((id) => !Number.isNaN(id));

    const idsForSelectionActions = isAllRowsSelected ? [] : selectedIdsWithoutPossibleParentIds;

    const getSelectedRowsCount = () => {
        if (isAllRowsSelected) {
            return totalElements;
        }

        return isExpandable ? selectedIdsWithoutPossibleParentIds.length : Object.values(rowSelection).length;
    };

    return (
        <TableLayout maxWidth={maxWidth}>
            {isSelectable && (
                <TableSelectionTop>
                    <Grid alignItems="center" gridAutoFlow="column" gap="3rem">
                        <Text size="1.4rem">
                            {isAllRowsSelected && isExpandable
                                ? t("common.selection.totalExpand")
                                : t("common.selection.total", {
                                      total: getSelectedRowsCount() ?? 0,
                                  })}
                        </Text>
                        {!isAllRowsSelected && canSelectAll && (
                            <Button type="button" onClick={selectAllPages} variant="subtle">
                                {isExpandable
                                    ? t("common.selection.selectAllWithExpand")
                                    : t("common.selection.selectAll", { total: totalElements })}
                            </Button>
                        )}
                        {(Object.keys(rowSelection).length > 0 || isAllRowsSelected) && (
                            <Button type="button" onClick={deselectAllPages} variant="subtle">
                                {t("common.selection.deselectAll")}
                            </Button>
                        )}
                    </Grid>
                    {actionsForSelection ? actionsForSelection(isAllRowsSelected, idsForSelectionActions, columnFilters) : null}
                </TableSelectionTop>
            )}
            <TableWithPaginationLayout>
                <TableScrollDecoration
                    isLeftDecorationVisible={isLeftDecorationVisible}
                    isRightDecorationVisible={isRightDecorationVisible}
                >
                    <TableBorder ref={wrapperRef}>
                        <LoadingOverlay loading={isLoading} />
                        <Table ref={tableRef}>
                            <THead<RecordType>
                                headerGroups={headerGroups}
                                areFiltersDirty={areFiltersDirty}
                                resetFilters={resetFiltersToInitialValue}
                                t={t}
                                columnFilters={columnFilters}
                                setColumnFilters={setColumnFilters}
                            />
                            <TBody>
                                {rows.length === 0 && (
                                    <EmptyRow t={t} customEmptyRow={emptyRow} colSpan={allColumns.length} dirtyFilters={areFiltersDirty} />
                                )}
                                {rows.map((row) => createRow(row, overrideRowProps, actions))}
                            </TBody>
                        </Table>
                    </TableBorder>
                </TableScrollDecoration>
                <Pagination
                    setPageSize={changePageSize}
                    activePage={pageIndex + 1}
                    setPage={(activePage) => table.setPageIndex(activePage - 1)}
                    total={Math.ceil((totalElements ?? pageSize) / pageSize)}
                    pageSize={pageSize}
                />
            </TableWithPaginationLayout>
        </TableLayout>
    );
};

const createRow = <RecordType extends TableRecordType>(
    row: Row<RecordType>,
    overrideRowProps: TableContainerProps<RecordType>["overrideRowProps"],
    actions?: TableContainerProps<RecordType>["actions"],
) => {
    const overrideProps = overrideRowProps ? overrideRowProps(row) : {};
    const colors =
        row.getCanExpand() && row.depth === 0
            ? { $backgroundColor: Color.neutral50, $hoveredColor: Color.supportNavy50 }
            : { $backgroundColor: Color.white, $hoveredColor: Color.accent300 };

    return (
        <TRow key={row.id} {...colors} {...overrideProps}>
            {createCells(row, actions)}
        </TRow>
    );
};

const createCells = <RecordType extends TableRecordType>(row: Row<RecordType>, actions?: TableContainerProps<RecordType>["actions"]) => {
    const tableCells = row.getVisibleCells();
    const cells = [];

    for (let i = 0; i < tableCells.length; i += 1) {
        if (row.getCanExpand() && row.depth === 0) {
            const cell = tableCells[i];
            const colspan = cell.column.columnDef.meta?.expandedRowColspan ?? 1;

            cells.push(createCell(row, cell, actions, colspan));

            i += colspan - 1;
        } else {
            cells.push(createCell(row, tableCells[i], actions));
        }
    }

    return cells;
};

const createCell = <RecordType extends TableRecordType>(
    row: Row<RecordType>,
    cell: Cell<RecordType, RecordType>,
    actions: TableContainerProps<RecordType>["actions"],
    colspan?: number,
) => (
    <TCell key={cell.id} colSpan={colspan} {...collectCellProps(cell.column.columnDef.meta)}>
        {cell.column.id === ACTION_COLUMN_ID && actions ? actions(row) : flexRender(cell.column.columnDef.cell, cell.getContext())}
    </TCell>
);

const createInitialState = (defaultTableConfig: DefaultTableConfig) => ({
    pagination: {
        pageSize: defaultTableConfig.pageSize,
        pageIndex: defaultTableConfig.pageIndex,
    },
});
