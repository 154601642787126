import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetCustomersToUnblockQuery } from "src/api";
import { createBooleanSelectOption, SelectColumnFilter, TableLink, TextColumnFilter } from "src/components";
import { routes } from "src/routes";
import { createTableModule, TableNames } from "src/table";
import { Color } from "src/theme";
import type { CustomerDebtListDto } from "src/types";
import { currencyFormatter } from "src/utils";
import { IsHospitalCell } from "../../../components";

const columnHelper = createColumnHelper<CustomerDebtListDto>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("name", {
        id: "name",
        header: t("customers.table.payerName") as string,
        cell: ({ row }) => (
            <TableLink
                color={Color.primary600}
                weight={700}
                to={routes.customerDetail.fillPathParams({
                    customerId: row.original.id.toString(),
                })}
            >
                {row.original.name}
            </TableLink>
        ),
        meta: {
            minWidth: 120,
            width: 350,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("acn", {
        id: "acn",
        header: t("customers.table.acn") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("tin", {
        id: "tin",
        header: t("customers.table.tin") as string,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("branches", {
        id: "branches",
        header: t("customers.table.branches") as string,
        cell: ({ row }) => <IsHospitalCell branches={row.original.branches} t={t} />,
        meta: {
            minWidth: 75,
            options: createBooleanSelectOption(t("common.yes"), t("common.no")),
            filterComponent: SelectColumnFilter,
        },
    }),
    columnHelper.accessor("totalGross", {
        id: "totalGross",
        header: t("customers.table.totalGross") as string,
        enableColumnFilter: false,
        cell: (cellContext) => currencyFormatter(cellContext.getValue()),
        meta: {
            minWidth: 100,
            noWrap: true,
            align: "end",
        },
    }),
];

export const tableModule = createTableModule<CustomerDebtListDto>(
    TableNames.customersUnblocked,
    useGetCustomersToUnblockQuery,
    createColumns,
);
