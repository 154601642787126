import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "i18next";

import { HighlightCell, TableLink, Tooltip } from "src/components";
import { routes } from "src/routes";
import { Color } from "src/theme";
import type { SupplyLimitSuggestionDto } from "src/types";

const columnHelper = createColumnHelper<SupplyLimitSuggestionDto>();

export const createColumns = (t: TFunction) => [
    columnHelper.accessor("shipToName", {
        id: "shipToName",
        header: t("dashboard.supplyLimitSuggestions.table.name") as string,
        cell: ({ row }) => (
            <TableLink to={routes.customerDetail.fillPathParams({ customerId: row.original.shipToId.toString() })}>
                {row.original.shipToName}
            </TableLink>
        ),
        enableColumnFilter: false,
        meta: {
            width: 350,
            minWidth: 150,
        },
    }),
    columnHelper.accessor("shipToAcn", {
        id: "shipToAcn",
        header: t("dashboard.supplyLimitSuggestions.table.acn") as string,
        enableColumnFilter: false,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("productPackageName", {
        id: "productPackageName",
        header: t("dashboard.supplyLimitSuggestions.table.product"),
        enableColumnFilter: false,
        cell: ({ row }) => (
            <TableLink to={routes.supplyLimitsDetail.fillPathParams({ productPackageId: row.original.productPackageId.toString() })}>
                {row.original.productPackageName}
            </TableLink>
        ),
        meta: {
            width: 250,
            minWidth: 150,
        },
    }),
    columnHelper.accessor("currentTierNumber", {
        id: "currentTierNumber",
        header: t("dashboard.supplyLimitSuggestions.table.currentTier"),
        enableColumnFilter: false,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("currentTierMonthlyLimit", {
        id: "currentTierMonthlyLimit",
        header: t("dashboard.supplyLimitSuggestions.table.currentMonthlyLimit"),
        enableColumnFilter: false,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("averageRequestedAmount", {
        id: "averageRequestedAmount",
        header: t("dashboard.supplyLimitSuggestions.table.averageRequestedAmount"),
        enableColumnFilter: false,
        meta: {
            minWidth: 150,
        },
    }),
    columnHelper.accessor("approvedEmergencyOrdersCount", {
        id: "approvedEmergencyOrdersCount",
        header: t("dashboard.supplyLimitSuggestions.table.approvedCount"),
        enableColumnFilter: false,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("suggestedTierNumber", {
        id: "suggestedTierNumber",
        header: t("dashboard.supplyLimitSuggestions.table.suggestedTierNumber"),
        enableColumnFilter: false,
        cell: (value) => (value ? <HighlightCell highlight>{value.getValue()}</HighlightCell> : null),
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("suggestedTierMonthlyLimit", {
        id: "suggestedTierMonthlyLimit",
        header: t("dashboard.supplyLimitSuggestions.table.suggestedTierMonthlyLimit"),
        enableColumnFilter: false,
        cell: (value) => (value ? <HighlightCell highlight>{value.getValue()}</HighlightCell> : null),
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("suggestedTierMonthlyLimit", {
        id: "idealMonthlyLimit",
        header: t("dashboard.supplyLimitSuggestions.table.idealMonthlyLimit"),
        enableColumnFilter: false,
        cell: ({ row }) => (
            <Tooltip
                withinPortal={false}
                color={Color.supportGraphite500}
                backgroundColor={Color.accent50}
                withArrow
                label={t("dashboard.supplyLimitSuggestions.table.idealMonthlyLimitTooltip")}
            >
                <span>{row.original.currentTierMonthlyLimit + row.original.averageRequestedAmount} </span>
            </Tooltip>
        ),
    }),
];
