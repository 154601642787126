import type { ColumnFilter } from "@tanstack/react-table";
import type { TFunction } from "i18next";

import { convertCustomersToBlockUnblockFilterNames } from "src/api";
import { Grid, Icon, Text } from "src/components";
import { Color, IconSizes } from "src/theme";
import { CustomerActionRequestDto, CustomerCategoryEnum } from "src/types";

export const customerCategoryFormatter = (t: TFunction, category: CustomerCategoryEnum) => {
    switch (category) {
        case CustomerCategoryEnum.Payer:
            return t(`enum.customerCategory.${CustomerCategoryEnum.Payer}`);
        case CustomerCategoryEnum.ShipTo:
            return t(`enum.customerCategory.${CustomerCategoryEnum.ShipTo}`);
        default:
            return "";
    }
};

export const createEmptyRowForRequestTables = (emptyRowText: string, emptyRowWithFiltersText: string) => (areFiltersDirty?: boolean) =>
    (
        <Grid justifyItems="center" gap="1rem">
            <Icon.PersonProhibited color={Color.supportNavy100} size={IconSizes.xxl} />
            <Text color={Color.supportNavy300}>{areFiltersDirty ? emptyRowWithFiltersText : emptyRowText}</Text>
        </Grid>
    );

export const getParamsForBlockUnblockTables = (
    isAllRowsSelected: boolean,
    selectedRowIds: number[],
    filters: ColumnFilter[],
): CustomerActionRequestDto => {
    const filterDto = convertCustomersToBlockUnblockFilterNames(filters.reduce((all, { id, value }) => ({ [id]: value, ...all }), {}));
    return isAllRowsSelected
        ? {
              filterDto,
          }
        : {
              filterDto,
              customerIds: selectedRowIds,
          };
};
