import { type ColumnFilter, type HeaderGroup, ColumnMeta, flexRender } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { CSSProperties, Dispatch, SetStateAction } from "react";
import styled, { css } from "styled-components";

import type { TableRecordType } from "src/types";
import { isEmpty } from "src/utils";
import { ACTION_COLUMN_ID } from "../../core/table/constants";
import { ResetFilterButton } from "./ResetFilterButton";

type THeadProps<RecordType extends TableRecordType> = Readonly<{
    headerGroups: Array<HeaderGroup<RecordType>>;
    areFiltersDirty: boolean;
    t: TFunction;
    columnFilters: ColumnFilter[];
    setColumnFilters: Dispatch<SetStateAction<ColumnFilter[]>>;
    resetFilters: () => void;
}>;

export type ColumnHeaderProps = {
    collapse?: boolean;
    maxWidth?: number;
    minWidth?: number;
    width?: number;
    headerVerticalAlign?: CSSProperties["verticalAlign"];
};

export const collectHeaderProps = <TData, TValue>(
    metaData: ColumnMeta<TData, TValue>,
): ColumnHeaderProps & { readonly className: string } => ({
    className: metaData?.collapse ? "collapse" : "",
    width: metaData?.width,
    maxWidth: metaData?.maxWidth,
    minWidth: metaData?.minWidth,
    headerVerticalAlign: metaData?.headerVerticalAlign,
});

/**
 * Table header component
 * Ready to use with Table component
 */
export const THead = <RecordType extends TableRecordType>({
    headerGroups,
    areFiltersDirty,
    resetFilters,
    t,
    columnFilters,
    setColumnFilters,
}: THeadProps<RecordType>): JSX.Element => (
    <thead>
        {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                    const meta = header.column.columnDef.meta ?? {};
                    const headerProps = collectHeaderProps(meta);
                    const columnId = header.column.id;

                    const columnFilter = columnFilters.find((filter) => filter.id === columnId);
                    const setFilter = (filter: ColumnFilter | undefined) => {
                        if (isEmpty(filter?.value) && columnFilters.length) {
                            setColumnFilters(columnFilters.filter((f) => f.id !== columnId));
                        } else
                            setColumnFilters((prev) =>
                                prev
                                    .filter((f) => f.id !== columnId)
                                    .concat({
                                        id: columnId,
                                        value: filter?.value,
                                    }),
                            );
                    };

                    const Filter = meta?.filterComponent;

                    return (
                        <Th key={header.id} {...headerProps}>
                            <ThWrapper $align={meta?.align}>
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}

                                {header.column.id === ACTION_COLUMN_ID ? (
                                    <ResetFilterButton hidden={!areFiltersDirty} onClick={resetFilters}>
                                        {t("common.reset")}
                                    </ResetFilterButton>
                                ) : header.column.getCanFilter() && Filter ? (
                                    <Filter
                                        columnId={columnId}
                                        columnFilter={columnFilter}
                                        setColumnFilter={setFilter}
                                        options={meta?.options}
                                    />
                                ) : null}
                            </ThWrapper>
                        </Th>
                    );
                })}
            </tr>
        ))}
    </thead>
);

const Th = styled.th<ColumnHeaderProps>`
    background-color: ${({ theme }) => theme.color.primary600};
    padding: 1.2rem;
    box-sizing: content-box;
    text-align: left;
    color: ${({ theme }) => theme.color.neutral50};
    font-size: 1.6rem;
    vertical-align: ${({ headerVerticalAlign }) => headerVerticalAlign || "top"};
    font-weight: 700;
    ${({ maxWidth }) =>
        maxWidth &&
        css`
            max-width: ${maxWidth}px;
        `}
    ${({ minWidth }) =>
        minWidth &&
        css`
            min-width: ${minWidth}px;
        `}
`;

const ThWrapper = styled.div<{ $align?: CSSProperties["justifyContent"] }>`
    display: grid;
    font-size: 1.6rem;
    grid-auto-flow: row;
    gap: 1.5rem;
    ${({ $align }) =>
        $align &&
        css`
            justify-items: ${$align};
        `}
`;
