import { nanoid } from "@reduxjs/toolkit";

import type { TableRecordType } from "src/types";
import { createResetSelectionAction } from "./actions";
import { NAME } from "./constants";
import createContainer from "./createContainer";
import type { TableNames } from "./enums";
import { reducer } from "./reducer";
import type { QueryHookType } from "./types";

export { LongTextCell, ParentRow } from "./components";
export { ICON_TABLE_SIZE } from "./constants";
export { TableNames } from "./enums";
export * from "./types";
export { createFiltersSearchParam } from "./utils";

export const createTableModule = <RecordType extends TableRecordType>(
    tableName: TableNames,
    useGetData: QueryHookType<RecordType>,
    // TODO :: add comment why is this any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createColumns: any,
) => {
    const tableId = nanoid();
    const Container = createContainer<RecordType>(tableName, tableId, useGetData, createColumns);
    const resetTableSelectionAction = createResetSelectionAction(tableId);
    return {
        Container,
        resetTableSelectionAction,
    };
};

export default {
    NAME,
    reducer,
};
