import type { Row } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { Button, Confirm, Grid, Icon, TableActionForSelection } from "src/components";
import { routes } from "src/routes";
import { Color, IconSizes } from "src/theme";
import { type EmergencyOrderProductPackageListDto, type ExpandEmergencyOrdersType, CustomerCredibilityEnum } from "src/types";
import { LinkToPublicEmergencyForm } from "../../components";
import { ApproveWithXOrdersForm } from "./ApproveWithXOrdersForm";
import { RefuseForm } from "./RefuseForm";

export const createActionsForSelection =
    (t: TFunction, bulkApprove: (value: { emergencyOrderProductPackageIds: number[] }) => void) => (_: boolean, selectedIds: number[]) => {
        return (
            <TableActionForSelection>
                <LinkToPublicEmergencyForm target="_blank" href={routes.emergencyOrders.path}>
                    <Grid gridAutoFlow="column" gap="0.5rem">
                        {t("dashboard.emergencyOrders.create")}
                        <Icon.Open size={IconSizes.s} filled />
                    </Grid>
                </LinkToPublicEmergencyForm>
                <ApproveWithXOrdersForm selectedIds={selectedIds} />
                <Confirm
                    title={t("dashboard.emergencyOrders.approveConfirm.title")}
                    content={t("dashboard.emergencyOrders.approveConfirm.content")}
                    confirmButtonTransKey="dashboard.emergencyOrders.approveSelected"
                    onConfirm={() => bulkApprove({ emergencyOrderProductPackageIds: selectedIds })}
                >
                    {(open) => (
                        <Button disabled={selectedIds.length === 0} type="button" onClick={open}>
                            {t("dashboard.emergencyOrders.approveSelected")}
                        </Button>
                    )}
                </Confirm>
                <RefuseForm selectedIds={selectedIds} />
            </TableActionForSelection>
        );
    };

export const overrideRowProps = (row: Row<EmergencyOrderProductPackageListDto>): Record<string, unknown> =>
    getCredibilityColor(row.getValue("customerCredibility"));

export const overrideGroupedTableRowProps = (row: Row<ExpandEmergencyOrdersType>): Record<string, unknown> =>
    !row.getCanExpand() ? getCredibilityColor(row.getValue("customerCredibility")) : { $backgroundColor: Color.white };

const getCredibilityColor = (credibility: CustomerCredibilityEnum | undefined): Record<string, unknown> => {
    switch (credibility) {
        case CustomerCredibilityEnum.Good:
            return { $backgroundColor: Color.success50, $hoveredColor: Color.success100 };
        case CustomerCredibilityEnum.Suspicious:
            return { $backgroundColor: Color.warning50, $hoveredColor: Color.warning100 };
        case CustomerCredibilityEnum.Bad:
            return { $backgroundColor: Color.error50, $hoveredColor: Color.error100 };
        default:
            return { $backgroundColor: Color.white };
    }
};
