import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetProductPricesQuery } from "src/api";
import { DateColumnFilter, TextColumnFilter } from "src/components";
import { createTableModule, ParentRow, TableNames } from "src/table";
import {
    type ExpandTableProductPackageWithPricesType,
    type ProductPackageWithPrices,
    type ProductPrice,
    isProductPackageWithPrices,
} from "src/types";
import { currencyFormatter, dateTimeFormatter } from "src/utils";

const columnHelper = createColumnHelper<ProductPackageWithPrices | ProductPrice>();
const createColumns = (t: TFunction) => [
    columnHelper.accessor("name", {
        id: "name",
        header: t("prices.table.name") as string,
        cell: ({ row }) => (isProductPackageWithPrices(row.original) ? <ParentRow>{row.original.name}</ParentRow> : row.original.name),
        meta: {
            minWidth: 100,
            width: 250,
            noWrap: true,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("validFrom", {
        id: "validFrom",
        header: t("prices.table.validFrom") as string,
        cell: (cellContext) => dateTimeFormatter(cellContext.getValue() ?? ""),
        meta: {
            minWidth: 100,
            filterComponent: DateColumnFilter,
        },
    }),
    columnHelper.accessor("validTo", {
        id: "validTo",
        header: t("prices.table.validTo") as string,
        cell: (cellContext) => dateTimeFormatter(cellContext.getValue() ?? ""),
        meta: {
            minWidth: 100,
            filterComponent: DateColumnFilter,
        },
    }),
    columnHelper.accessor("price", {
        id: "price",
        header: t("prices.table.price") as string,
        enableColumnFilter: false,
        cell: (cellContext) => currencyFormatter(cellContext.getValue()),
        meta: {
            minWidth: 100,
            align: "flex-end",
            noWrap: true,
        },
    }),
];

export const tableModule = createTableModule<ExpandTableProductPackageWithPricesType>(
    TableNames.productPrice,
    useGetProductPricesQuery,
    createColumns,
);
