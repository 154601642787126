import styled, { css } from "styled-components";

import type { Color } from "src/theme";

/**
 * Table row component
 * Ready to use with Table component
 */
export const TRow = styled.tr<{ $backgroundColor?: Color; $hoveredColor?: Color; $disabled?: boolean }>`
    background-color: ${({ $backgroundColor }) => $backgroundColor || "white"};
    transition: background-color 100ms ease;

    ${({ theme, $disabled }) =>
        $disabled &&
        css`
            & td,
            & td a {
                color: ${theme.color.supportGraphite300} !important;
                // TODO :: Disabled color is outside color range, tell it to designer
                background-color: #fcfafa;
            }
        `}

    ${({ $hoveredColor }) =>
        $hoveredColor &&
        css`
            &:hover {
                background-color: ${$hoveredColor};
            }
        `}
`;
