import { createColumnHelper } from "@tanstack/react-table";
import type { TFunction } from "react-i18next";

import { useGetInterfaceOperationsQuery } from "src/api";
import { SelectColumnFilter, TextColumnFilter } from "src/components";
import { createTableModule, TableNames } from "src/table";
import type { PhoenixInterfaceOperationDto } from "src/types";
import { PhoenixInterfaceOperationDtoInterfaceTypeEnum, PhoenixInterfaceOperationDtoStatusEnum } from "src/types";
import { DATE_TIME_FORMAT, dateTimeFormatter, enumToLocalizedOptions, getTranslatedEnumValue } from "src/utils";

const columnHelper = createColumnHelper<PhoenixInterfaceOperationDto>();
const createColumns = (t: TFunction, tableName: string) => [
    columnHelper.accessor("operationTimestamp", {
        id: "operationTimestamp",
        header: t("importExport.importInterfaces.table.operationTimestamp") as string,
        enableColumnFilter: false,
        cell: (cellContext) => dateTimeFormatter(cellContext.getValue(), DATE_TIME_FORMAT),
        meta: {
            minWidth: 150,
        },
    }),
    columnHelper.accessor("sequenceNumber", {
        id: "sequenceNumber",
        header: t("importExport.importInterfaces.table.sequenceNumber") as string,
        enableColumnFilter: false,
        meta: {
            minWidth: 100,
        },
    }),
    columnHelper.accessor("interfaceType", {
        id: "interfaceType",
        header: t("importExport.importInterfaces.table.interfaceType") as string,
        enableColumnFilter: true,
        cell: (cellContext) => getTranslatedEnumValue("PhoenixInterfaceOperationDtoInterfaceType", cellContext.getValue()),
        meta: {
            minWidth: 120,
            filterComponent: SelectColumnFilter,
            options: enumToLocalizedOptions(
                "PhoenixInterfaceOperationDtoInterfaceType",
                PhoenixInterfaceOperationDtoInterfaceTypeEnum,
                tableName === TableNames.importInterfaces
                    ? [PhoenixInterfaceOperationDtoInterfaceTypeEnum.TradingTerms, PhoenixInterfaceOperationDtoInterfaceTypeEnum.Quotas]
                    : [
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.AccountReceivables,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.DeliveryNotes,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.Invoices,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.InventoryMovements,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.MasterData,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.RejectedOrders,
                          PhoenixInterfaceOperationDtoInterfaceTypeEnum.StockBalance,
                      ],
            ),
        },
    }),
    columnHelper.accessor("filename", {
        id: "filename",
        header: t("importExport.importInterfaces.table.filename") as string,
        enableColumnFilter: true,
        meta: {
            minWidth: 100,
            filterComponent: TextColumnFilter,
        },
    }),
    columnHelper.accessor("status", {
        id: "status",
        header: t("importExport.importInterfaces.table.status") as string,
        enableColumnFilter: true,
        cell: (cellContext) => getTranslatedEnumValue("PhoenixInterfaceOperationDtoStatus", cellContext.getValue()),
        meta: {
            minWidth: 120,
            filterComponent: SelectColumnFilter,
            options: enumToLocalizedOptions("PhoenixInterfaceOperationDtoStatus", PhoenixInterfaceOperationDtoStatusEnum),
        },
    }),
    columnHelper.accessor("errorMessage", {
        id: "errorMessage",
        header: t("importExport.importInterfaces.table.errorMessage") as string,
        enableColumnFilter: true,
        meta: {
            minWidth: 150,
            filterComponent: TextColumnFilter,
        },
    }),
];

export const importInterfaceTableModule = createTableModule<PhoenixInterfaceOperationDto>(
    TableNames.importInterfaces,
    useGetInterfaceOperationsQuery,
    createColumns,
);

export const exportInterfaceTableModule = createTableModule<PhoenixInterfaceOperationDto>(
    TableNames.exportInterfaces,
    useGetInterfaceOperationsQuery,
    createColumns,
);
