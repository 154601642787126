import type { FC } from "react";

import { useGetSupplyLimitsQuery } from "src/api";
import { MultiSelect, TABLE_FILTER_HEIGHT } from "src/components";
import { routes } from "src/routes";
import { UNLIMITED_TIER_NUMBER } from "src/supplyLimitSelect";
import { useTranslation } from "src/translations";
import type { ColumnFilterProps } from "src/types";
import { isString } from "src/utils";

export const TierColumnFilter: FC<ColumnFilterProps> = ({ columnId, columnFilter, setColumnFilter }) => {
    const filterValue = columnFilter ? (columnFilter.value as string[]) : undefined;

    const detailParams = routes.supplyLimitsBulkEdit.useParams();
    const { t } = useTranslation();
    const { data: supplyLimits, isLoading } = useGetSupplyLimitsQuery(detailParams.productPackageId, {
        skip: !detailParams.productPackageId,
    });

    const options = supplyLimits?.map(({ id, tierNumber }) => {
        const label = tierNumber === UNLIMITED_TIER_NUMBER ? t("common.unlimited") : tierNumber?.toString() || "";
        return {
            value: id,
            label,
        };
    });

    return (
        <MultiSelect
            options={isLoading || !options ? [] : options}
            withoutBorder
            withoutErrorPlaceholder
            height={TABLE_FILTER_HEIGHT}
            fontSize="1.2rem"
            value={isString(filterValue) ? [filterValue] : filterValue}
            disableOverflow
            withinPortal
            maxDropdownHeight={300}
            clearable
            searchInputWidth="60px"
            onChange={(value) => {
                setColumnFilter(value?.length ? { id: columnId, value } : undefined); // Set undefined to remove the filter entirely
            }}
        />
    );
};
