import type { Row } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { ReactNode } from "react";

import { TableAction, TableActionButton } from "src/components";
import { Color } from "src/theme";
import { type CustomerListDto, CustomerCategoryEnum } from "src/types";

export const createActionColumn =
    (t: TFunction, setSelectedCustomerId: (customerId: number) => void) =>
    ({ original }: Row<CustomerListDto>): ReactNode => {
        return original.category === CustomerCategoryEnum.Payer || original.payerApproved ? (
            <TableAction>
                <TableActionButton type="button" onClick={() => setSelectedCustomerId(original.id)}>
                    {t("customers.table.approve")}
                </TableActionButton>
            </TableAction>
        ) : null;
    };

export const overrideRowProps = (row: Row<CustomerListDto>): Record<string, unknown> => {
    if (row.original.category === CustomerCategoryEnum.ShipTo && !row.original.payerApproved) {
        return { $backgroundColor: Color.neutral100 };
    }
    return { $backgroundColor: Color.neutral50 };
};
