import type { FC } from "react";
import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";
import { useTranslation } from "src/translations";

export const SupplyLimitHeaders: FC = () => {
    const { t } = useTranslation();
    return (
        <Headers>
            <Header>{t("supplyLimits.coreComponent.headers.label")}</Header>
            <Header>{t("supplyLimits.coreComponent.headers.dailyLimit")}</Header>
            <Header>{t("supplyLimits.coreComponent.headers.monthlyLimit")}</Header>
        </Headers>
    );
};

const Header = styled.div`
    padding-block: 1.6rem;
    display: grid;
    font-weight: 700;
    justify-content: center;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.color.supportNavy500};

    ${maxWidthBreakpoint.mobile(css`
        font-size: 1.4rem;
    `)};
`;

const Headers = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    // Place for "isDefault" mark
    padding-right: 3.5rem;
`;
