import type { ColumnFilter, Row } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { ReactNode } from "react";

import { convertPayersDistributionMarginTableFilterNames } from "src/api";
import { ActionIcon, Button, Grid, Icon, TableAction } from "src/components";
import { ConfirmContextConsumer } from "src/modal";
import { ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import { type ExpandTableDistributionMarginsType, isProductPackageWithMarginDto } from "src/types";
import type { ModalAttributes } from "./types";

export const createActionColumn =
    (t: TFunction, customerId: string, openModal: (value: ModalAttributes) => void) =>
    (row: Row<ExpandTableDistributionMarginsType>): ReactNode => {
        const productPackageWithMargin = isProductPackageWithMarginDto(row.original) ? row.original : undefined;

        return !row.getCanExpand() && productPackageWithMargin ? (
            <TableAction>
                <ActionIcon
                    tooltip={t("payerDetail.distributionMargins.editTooltip")}
                    onClick={() =>
                        openModal({
                            productPackagesWithMarginIds: [productPackageWithMargin.id],
                            name: productPackageWithMargin?.productPackageName,
                            marginValue: productPackageWithMargin?.margin,
                            filterFromTable: { customerId: parseInt(customerId, 10) },
                        })
                    }
                    color={Color.accent100}
                >
                    <Icon.Edit size={ICON_TABLE_SIZE} color={Color.primary600} filled />
                </ActionIcon>
            </TableAction>
        ) : null;
    };

export const createActionsForSelection =
    (t: TFunction, customerId: string, openModal: (value: ModalAttributes) => void, onResetMargins: (value: ModalAttributes) => void) =>
    (isAllRowsSelected: boolean, selectedRowIds: number[], filters: ColumnFilter[]) => {
        const filterDto = filters.reduce((all, { id, value }) => ({ [id]: value, ...all }), { customerId: parseInt(customerId, 10) });
        const attributes = {
            productPackagesWithMarginIds: selectedRowIds,
            filterFromTable: convertPayersDistributionMarginTableFilterNames(filterDto),
        };
        const onChangeSelected = () => {
            openModal(attributes);
        };
        return (
            <Grid gridAutoFlow="column" gap="1rem">
                <ConfirmContextConsumer>
                    {({ showConfirm }) => {
                        const confirm = async () => {
                            const confirmResult = await showConfirm({
                                title: t("payerDetail.distributionMargins.resetMargins.title"),
                                content: t("payerDetail.distributionMargins.resetMargins.content"),
                            });
                            if (confirmResult) {
                                onResetMargins(attributes);
                            }
                        };
                        return (
                            <Button
                                disabled={!isAllRowsSelected && Object.keys(selectedRowIds).length === 0}
                                type="button"
                                onClick={confirm}
                                variant="outline"
                            >
                                {t("payerDetail.distributionMargins.resetMargins.button")}
                            </Button>
                        );
                    }}
                </ConfirmContextConsumer>
                <Button disabled={!isAllRowsSelected && Object.keys(selectedRowIds).length === 0} type="button" onClick={onChangeSelected}>
                    {t("payerDetail.distributionMargins.changeSelected")}
                </Button>
            </Grid>
        );
    };
