import type { FC } from "react";

import { Text } from "src/components";
import { Color } from "src/theme";

export const ParentRow: FC = ({ children }) => (
    <Text color={Color.primary600} weight={700} uppercase whiteSpace="nowrap">
        {children}
    </Text>
);
