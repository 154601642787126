import { css } from "styled-components";

export type InputStylesProps = {
    height?: string;
    fontSize?: string;
    maxWidth?: string;
    minWidth?: string;
    withoutBorder?: boolean;
    withoutErrorPlaceholder?: boolean;
};

export const createInputStyles = ({ height, fontSize, maxWidth, withoutBorder }: InputStylesProps) => css`
    max-width: ${maxWidth || "100%"};
    height: ${height || "3rem"};
    line-height: ${height || "3rem"};
    color: ${({ theme }) => theme.color.supportNavy500};
    font-family: ${({ theme }) => theme.fontFamily};
    border-radius: ${({ theme }) => theme.radius.default};
    ${withoutBorder
        ? css`
              border: none;
          `
        : css`
              border: 1px solid ${({ theme }) => theme.color.neutral300};
          `}

    font-size: ${fontSize || "1.4rem"};
    min-height: unset;

    &::placeholder {
        font-size: 1.4rem;
        color: ${({ theme }) => theme.color.supportNavy200};
    }

    &:focus-within {
        border-color: ${({ theme }) => theme.color.primary600};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.color.neutral200};
        color: ${({ theme }) => theme.color.supportGraphite300};
    }
`;

export const createInputWrapperStyles = ({ withoutErrorPlaceholder, maxWidth, minWidth }: InputStylesProps) => css`
    &.mantine-InputWrapper-root {
        max-width: ${maxWidth || "100%"};
        position: relative;
        ${minWidth &&
        css`
            min-width: ${minWidth};
        `}
        ${!withoutErrorPlaceholder &&
        css`
            padding-bottom: 2.2rem;
        `}
        & .mantine-Input-wrapper {
            margin-bottom: 0;
        }
    }
`;

export const labelStyles = css`
    margin-bottom: 0.3rem;
    font-family: ${({ theme }) => theme.fontFamily};

    font-size: 1.4rem;
    font-weight: 400;
    color: ${({ theme }) => theme.color.supportNavy500};

    & .mantine-TextInput-required,
    & .mantine-Textarea-required,
    & .mantine-Select-required,
    & .mantine-NumberInput-required {
        color: ${({ theme }) => theme.color.primary600};
    }
`;

export const invalidInputStyles = css`
    color: ${({ theme }) => theme.color.error500};
    border-color: ${({ theme }) => theme.color.error500};
`;

export const errorMessageStyles = css`
    display: flex;

    margin-top: 0.3rem;

    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.5;
    color: ${({ theme }) => theme.color.error500} !important;

    // Error label position without jumps in layout
    position: absolute;
    white-space: nowrap;
    left: 0;
    bottom: 0;
`;
