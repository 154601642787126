import type { Row } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { ReactNode } from "react";

import { ActionIcon, Icon, TableAction } from "src/components";
import { ICON_TABLE_SIZE } from "src/table";
import { Color } from "src/theme";
import {
    type ExpandTableProductWithSupplyLimitsType,
    type SupplyLimitWithPackageAndMarginDto,
    isSupplyLimitWithPackageAndMarginDto,
} from "src/types";
import { isEmpty } from "src/utils";

export const createActionColumn =
    (t: TFunction, openDrawer: (value: SupplyLimitWithPackageAndMarginDto) => void) =>
    (row: Row<ExpandTableProductWithSupplyLimitsType>): ReactNode => {
        const supplyLimitWithProductPackage = isSupplyLimitWithPackageAndMarginDto(row.original) ? row.original : undefined;

        return !row.getCanExpand() && !isEmpty(supplyLimitWithProductPackage) ? (
            <TableAction>
                <ActionIcon
                    tooltip={t("shipToDetail.supplyLimit.editTooltip")}
                    onClick={() => openDrawer(supplyLimitWithProductPackage)}
                    color={Color.accent100}
                >
                    <Icon.Edit size={ICON_TABLE_SIZE} color={Color.primary600} filled />
                </ActionIcon>
            </TableAction>
        ) : null;
    };
