import type { FC } from "react";
import { useState } from "react";

import { useResetDistributionMarginForPayerMutation } from "src/api";
import { Modal } from "src/components";
import { useModal } from "src/modal";
import { NotificationTypes, useNotifications } from "src/notifications";
import { routes } from "src/routes";
import { useTranslation } from "src/translations";
import { hasPropOfType, isNumber, useAppDispatch } from "src/utils";
import { PAYER_DISTRIBUTION_MARGINS_MODAL_ID } from "./constants";
import { DistributionMarginsForm } from "./DistributionMarginsForm";
import { tableModule } from "./table";
import type { ModalAttributes } from "./types";
import { createActionColumn, createActionsForSelection } from "./utils";

export const DistributionMargins: FC = () => {
    const { t } = useTranslation();
    const { customerId } = routes.customerDetail.useParams();
    const notifications = useNotifications();
    const dispatch = useAppDispatch();
    const [isModalOpened, openModal, closeModal] = useModal(PAYER_DISTRIBUTION_MARGINS_MODAL_ID);
    const [resetDistributionMargin] = useResetDistributionMarginForPayerMutation();
    const [modalAttributes, setModalAttributes] = useState<ModalAttributes | undefined>(undefined);

    const onOpenModal = (value: ModalAttributes) => {
        setModalAttributes(value);
        openModal();
    };

    const onResetMargins = (value: ModalAttributes) => {
        const notificationId = notifications.showNotification({
            title: t("common.form.loadingTitle"),
            message: t("payerDetail.distributionMargins.resetMargins.loading"),
            loading: true,
        });
        try {
            if (value?.filterFromTable && hasPropOfType(value.filterFromTable, "customerId", isNumber)) {
                resetDistributionMargin({
                    productPackageIds: value.productPackagesWithMarginIds,
                    filterDto: value.filterFromTable,
                })
                    .unwrap()
                    .then(() => dispatch(tableModule.resetTableSelectionAction));
            } else {
                throw new Error("No customerId found in filter, or it has wrong type.");
            }
            notifications.updateNotification({
                id: notificationId,
                loading: false,
                title: t("common.form.successTitle"),
                message: t("payerDetail.distributionMargins.resetMargins.success"),
                type: NotificationTypes.success,
            });
        } catch (e) {
            notifications.updateNotificationToGlobalError({
                id: notificationId,
                error: e,
                fallbackMessage: t(`payerDetail.distributionMargins.resetMargins.error`),
            });
        }
    };

    return (
        <>
            <tableModule.Container
                isSelectable
                additionalQueryParams={{ customerId }}
                actions={createActionColumn(t, customerId, onOpenModal)}
                isExpandable
                actionsForSelection={createActionsForSelection(t, customerId, onOpenModal, onResetMargins)}
            />
            <Modal
                title={
                    modalAttributes?.name
                        ? t("payerDetail.distributionMargins.title", { name: modalAttributes.name })
                        : t("payerDetail.distributionMargins.multipleTitle")
                }
                opened={isModalOpened}
                onClose={closeModal}
            >
                <DistributionMarginsForm closeModal={closeModal} modalAttributes={modalAttributes} />
            </Modal>
        </>
    );
};
