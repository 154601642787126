import type { FC } from "react";

import {
    useApproveEmergencyOrdersMutation,
    useGetEmergencyOrderProductPackageForShipToQuery,
    useRefuseEmergencyOrdersMutation,
} from "src/api";
import {
    Button,
    Flex,
    Grid,
    LabelValuePair,
    LoadingOverlay,
    ModalButtonLayout,
    NumberInput,
    NumberInputProps,
    TabularText,
    Text,
    Textarea,
    TextareaProps,
} from "src/components";
import { Form, GlobalFormError, InputField } from "src/form";
import { Color, useMaxWidthBreakpoint } from "src/theme";
import { useTranslation } from "src/translations";
import type { ApproveEmergencyOrderDto, RefuseEmergencyOrderDto } from "src/types";
import { isEmpty, useAppDispatch } from "src/utils";
import { Description, TierSample } from "./components";
import { EMERGENCY_ORDER_FORM_APPROVE_FIELD } from "./constants";
import { tableModule } from "./table";
import type { ShipToEmergencyOrdersFromValuesType } from "./types";
import { getApprovedAmountTextKey } from "./utils";

export type ShipToSupplyLimitFormProps = Readonly<{
    eoProductPackageId?: number;
    closeModal: () => void;
}>;

export const ShipToEmergencyOrdersForm: FC<ShipToSupplyLimitFormProps> = ({ eoProductPackageId, closeModal }) => {
    const isMobile = useMaxWidthBreakpoint.mobile();

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { data: emergencyOrder, isLoading } = useGetEmergencyOrderProductPackageForShipToQuery(
        { eoProductPackageId: eoProductPackageId!! },
        {
            skip: !eoProductPackageId,
        },
    );
    const [refuse] = useRefuseEmergencyOrdersMutation();
    const [approve] = useApproveEmergencyOrdersMutation();

    const initialValues = {
        approvedAmount: emergencyOrder?.requestedAmount,
        [EMERGENCY_ORDER_FORM_APPROVE_FIELD]: true,
    };

    const onSuccess = () => {
        dispatch(tableModule.resetTableSelectionAction);
        closeModal();
    };

    const onSubmit = (values: ShipToEmergencyOrdersFromValuesType) => {
        if (eoProductPackageId) {
            if (values[EMERGENCY_ORDER_FORM_APPROVE_FIELD]) {
                if (!isEmpty(values.refusalReason)) {
                    throw new GlobalFormError(t("shipToDetail.emergencyOrders.modal.spareRefusalReason"));
                }
                const approveRequest: ApproveEmergencyOrderDto = {
                    emergencyOrderProductPackageIds: [eoProductPackageId],
                    approvedAmount: values.approvedAmount,
                    azNote: values.azNote,
                };
                return approve(approveRequest);
            }
            if (isEmpty(values.refusalReason)) {
                throw new GlobalFormError(t("shipToDetail.emergencyOrders.modal.missingRefusalReason"));
            }
            const refuseRequest: RefuseEmergencyOrderDto = {
                emergencyOrderProductPackageIds: [eoProductPackageId],
                refusalReason: values.refusalReason,
                azNote: values.azNote,
            };
            return refuse(refuseRequest);
        }

        throw new GlobalFormError("No emergencyOrderProductPackageId found. Please contact administrator.");
    };

    return (
        <>
            <LoadingOverlay loading={isLoading} />
            {emergencyOrder ? (
                <Grid gap="2rem">
                    <Description>
                        {t("shipToDetail.emergencyOrders.modal.description", { description: emergencyOrder.orderReason })}
                    </Description>
                    <Form<ShipToEmergencyOrdersFromValuesType>
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSuccess={onSuccess}
                        unwrapOnSubmit={false}
                        loadingMessage={(values) =>
                            values[EMERGENCY_ORDER_FORM_APPROVE_FIELD]
                                ? t("shipToDetail.emergencyOrders.modal.loadingApproveMessage")
                                : t("shipToDetail.emergencyOrders.modal.loadingRefuseMessage")
                        }
                        successMessage={(values) =>
                            values[EMERGENCY_ORDER_FORM_APPROVE_FIELD]
                                ? t("shipToDetail.emergencyOrders.modal.successApproveMessage")
                                : t("shipToDetail.emergencyOrders.modal.successRefuseMessage")
                        }
                    >
                        {({ values, setFieldValue, submitForm }) => {
                            const hasRefusedValue = values?.approvedAmount!! < 1;
                            return (
                                <>
                                    <Grid gap="1.4rem" gridAutoFlow="row">
                                        <Text size="2.1rem" color={Color.neutral400}>
                                            {t("shipToDetail.emergencyOrders.modal.supplyLimits")}
                                        </Text>
                                        <Grid gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr)) " columnGap="4rem">
                                            <TabularText columnGap="0.5rem" rowGap="0.5rem" color={Color.supportNavy500}>
                                                <LabelValuePair
                                                    label={t("supplyLimits.tiers.todayOrders")}
                                                    value={emergencyOrder.todayOrderVolume}
                                                    valueAlign="right"
                                                />
                                                <LabelValuePair
                                                    label={t("supplyLimits.tiers.monthlyOrders")}
                                                    value={emergencyOrder.currentMonthOrderVolume}
                                                    valueAlign="right"
                                                />
                                            </TabularText>
                                            <TabularText columnGap="0.5rem" rowGap="0.5rem" color={Color.supportNavy500}>
                                                <LabelValuePair
                                                    label={t("supplyLimits.tiers.EOApprovedThisMonth")}
                                                    value={emergencyOrder.approvedThisMonth}
                                                    valueAlign="right"
                                                />
                                                <LabelValuePair
                                                    label={t("supplyLimits.tiers.EOProcessedThisMonth")}
                                                    value={emergencyOrder.processedThisMonth}
                                                    valueAlign="right"
                                                />
                                            </TabularText>
                                        </Grid>
                                        <Flex alignItems="center" gap="1.6rem">
                                            <Text color={Color.supportNavy500} weight={700} size={isMobile ? "1.4rem" : undefined}>
                                                {t("shipToDetail.emergencyOrders.modal.approveFor")}
                                            </Text>
                                            <Flex alignItems="end" gap="1rem">
                                                <InputField<NumberInputProps["value"], NumberInputProps>
                                                    name="approvedAmount"
                                                    type="number"
                                                    input={NumberInput}
                                                    inputProps={{
                                                        maxWidth: "90px",
                                                        minWidth: "70px",
                                                        withoutErrorPlaceholder: true,
                                                        min: 0,
                                                        max: 999,
                                                    }}
                                                />
                                                <Text color={Color.supportNavy300} size="1.1rem">
                                                    {t(
                                                        getApprovedAmountTextKey(
                                                            values?.approvedAmount!!,
                                                            emergencyOrder?.requestedAmount!!,
                                                            emergencyOrder?.eslMonthlyLimit!!,
                                                        ),
                                                    )}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                        <TierSample
                                            tierNumber={emergencyOrder.tierNumber}
                                            dailyLimit={emergencyOrder.dailyLimit}
                                            monthlyLimit={emergencyOrder.monthlyLimit}
                                            dailyVolumeAdjustment={emergencyOrder.dailyVolumeAdjustment}
                                            monthlyVolumeAdjustment={emergencyOrder.monthlyVolumeAdjustment}
                                            approvedAmount={values.approvedAmount}
                                        />
                                    </Grid>
                                    <InputField<TextareaProps["value"], TextareaProps>
                                        name="azNote"
                                        input={Textarea}
                                        inputProps={{
                                            label: t("shipToDetail.emergencyOrders.modal.note.label"),
                                        }}
                                    />
                                    <InputField<TextareaProps["value"], TextareaProps>
                                        name="refusalReason"
                                        input={Textarea}
                                        inputProps={{
                                            label: t("shipToDetail.emergencyOrders.modal.refusalReason.label"),
                                            description: t("shipToDetail.emergencyOrders.modal.refusalReason.description"),
                                        }}
                                    />
                                    <ModalButtonLayout>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                setFieldValue(EMERGENCY_ORDER_FORM_APPROVE_FIELD, true);
                                                submitForm();
                                            }}
                                            disabled={hasRefusedValue}
                                        >
                                            {t("common.approve")}
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                setFieldValue(EMERGENCY_ORDER_FORM_APPROVE_FIELD, false);
                                                submitForm();
                                            }}
                                            variant="outline"
                                        >
                                            {t("common.refuse")}
                                        </Button>
                                    </ModalButtonLayout>
                                </>
                            );
                        }}
                    </Form>
                </Grid>
            ) : null}
        </>
    );
};
